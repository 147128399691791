import YouTube from "../Assets/Img/youtube.png";
export default function Form() {
  return (
    <>
      <a
        href="https://youtu.be/FgAOG2xGIfI"
        target="_blank"
        rel="noreferrer"
        className="inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg bg-white px-4 py-1.5 text-base font-semibold leading-7 text-primary shadow-sm"
      >
        <img src={YouTube} alt="" width={35} />
        <span className="ml-3">ASSISTA VÍDEO</span>
      </a>
    </>
  );
}