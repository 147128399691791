import "./App.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Error from "./Pages/404";
import Terms from "./Pages/Terms";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/termsofservice" element={<Terms />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
