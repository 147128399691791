import React from "react";
import { FadeIn } from "react-slide-fade-in";
import Img18 from "../Assets/Img/image18.png";
import ProblemBox from "./ProblemBox";
import SolutionBox from "./SolutionBox";
export default function Solution() {
  return (
    <section
      style={{
        background: "linear-gradient(63.54deg, #6E0289 16.59%, #00C3B3 88.13%)",
      }}
    >
      <div
        className="grid lg:grid-cols-3  gap-10 p-10 lg:p-20 md:p-20 content-center"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <div>
          <FadeIn
            from="left"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={0}
          >
            <img
              className="content-center"
              src={Img18}
              alt=""
              style={{
                borderWidth: "9px",
                borderColor: "#ffffff",
                borderRadius: "100%",
              }}
            />
          </FadeIn>
        </div>
        <div className="text-left">
          <FadeIn
            from="right"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={0}
          >
            <ProblemBox />
            <p
              className="text-white mt-5 mb-5 md:mr-20 lg:mr-20"
              style={{
                fontSize: "26px",
                fontWeight: "600",
                lineHeight: "33px",
                letterSpacing: "0.6499999761581421px",
                textAlign: "left",
              }}
            >
              Jose não consegue acessar serviços financeiros:
            </p>
            <div className="text-left inline-block mb-3">
              <span class="red-dot"></span>
              <span className="text-white mt-0">
                Ele não encontra facilmente trabalhadores qualificados em sua
                área, então seus filhos a ajudam na fazenda.
              </span>
            </div>
            <div className="text-left inline-block mb-3">
              <span class="red-dot"></span>
              <span className="text-white mt-0">
                Ele não consegue encontrar seus próprios consumidores, então é
                pressionado por intermediários para reduzir seus custos.
              </span>
            </div>
            <div className="text-left inline-block">
              <span class="red-dot"></span>
              <span className="text-white mt-0">
                Ele não pode obter um empréstimo de um banco, então rapidamente
                chega ao limite com seu negócio agrícola.
              </span>
            </div>
          </FadeIn>
        </div>
        <div className="text-left ">
          <FadeIn
            from="right"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={0}
          >
            <SolutionBox />
            <p
              className="text-white mt-5 mb-5 md:mr-20 lg:mr-20"
              style={{
                fontSize: "26px",
                fontWeight: "600",
                lineHeight: "33px",
                letterSpacing: "0.6499999761581421px",
                textAlign: "left",
              }}
            >
              Com FAME, agora José pode:
            </p>

            <div className="text-left inline-block mb-3">
              <span class="green-dot"></span>
              <span className="text-white mt-0">
                Publicar facilmente um trabalho no FAME e encontrar um
                trabalhador e motoristas locais.
              </span>
            </div>
            <div className="text-left inline-block mb-3">
              <span class="green-dot"></span>
              <span className="text-white mt-0">
                Alcançar um público maior de consumidores para promover seus
                produtos éticos e orgânicos.
              </span>
            </div>
            <div className="text-left inline-block mb-3">
              <span class="green-dot"></span>
              <span className="text-white mt-0">
                Ganhar uma renda passiva de sua conta de poupança FAME e pegar
                emprestado fundos para investir em sua fazenda.
              </span>
            </div>
          </FadeIn>
        </div>
      </div>
    </section>
  );
}
