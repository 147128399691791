import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export default function Page() {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="inline-block transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg bg-primary px-4 mb-20 py-1.5 text-base font-semibold leading-7 text-white mt-16 shadow-sm"
      >
        DESCUBRA MAIS
      </button>
      {showModal ? (
        <>
          <div className="justify-center p-4 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl h-auto">
              {/*content*/}
              <div className=" rounded-lg shadow-lg w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <p
                    className="person-name m-5 lg:p-20 text-primary"
                    style={{
                      fontSize: "30px",
                      fontWeight: "600",
                      lineHeight: "55px",
                      letterSpacing: "1.4115043878555298px",
                    }}
                  >
                    EM BREVE
                  </p>
                  <div>
                    <button
                      className="p-1 ml-auto border-0 text-primary  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="text-primary focus:outline-none">
                        &#10006;
                      </span>
                    </button>
                  </div>
                </div>
                {/*body*/}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
