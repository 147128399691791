import React from "react";

export default function Terms() {
  return (
    <>
      <div className="bg-primary" style={{ height: "120px" }}></div>
      <section
        className=" lg:p-20 p-10"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <div className=" hero-list mt-10 text-left">FAME Terms of Service</div>
        <div className="text-left mt-10">
          O FAME Android, iOS, aplicativos baseados em navegador e sites são
          publicados, de propriedade e operados pela ALGOFAME, uma corporação
          francesa, suas afiliadas e entidades relacionadas (FAME", "Empresa",
          "nós", "nós" e "nosso"). Estes Termos de Uso (os “Termos”) regem o
          acesso e uso do usuário (“usuário” “você” “seu”) de nossos aplicativos
          móveis, aplicativos baseados em navegador, sites e aplicativos
          acessados por computador, dispositivo móvel ou outro (individual e
          coletivamente, o “Aplicativo”), bem como quaisquer produtos e serviços
          fornecidos pela FAME (os “Serviços FAME”) (o Aplicativo, juntamente
          com os Serviços FAME, referidos coletivamente como o “Serviço”).
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 1. ACEITAÇÃO DO ACORDO </p>
          <p className="mt-5">
            Estes termos de serviço (os "Termos") estabelecem os termos e
            condições juridicamente vinculativos que regem o seu uso do Serviço,
            incluindo todas as ferramentas relacionadas, aplicativos móveis,
            aplicativos da Web, aplicativos descentralizados, contratos
            inteligentes e interfaces de programação de aplicativos (
            coletivamente, a "Plataforma"). Os Termos se aplicam ao seu uso da
            Plataforma para qualquer finalidade, incluindo, entre outros, a
            visualização de dados históricos, informações de transações,
            estatísticas e informações sobre outras atividades que ocorrem na
            rede. Ao acessar ou usar a Plataforma de qualquer maneira, você
            reconhece que leu, entendeu e concorda em ficar vinculado a estes
            Termos.
          </p>
          <p className="mt-5">
            Como usuário da Plataforma, você tem o direito de acessar e
            visualizar dados históricos, informações de transações e outras
            atividades que ocorrem na plataforma. Você também tem a
            responsabilidade de cumprir todas as regras e diretrizes
            estabelecidas nestes Termos.
          </p>
          <p className="mt-5">
            É importante que você leia e entenda cuidadosamente estes Termos
            antes de usar a Plataforma. Seu uso da Plataforma constitui a
            aceitação destes Termos e cria um contrato juridicamente vinculativo
            entre você e a empresa que opera a Plataforma.
          </p>
          <p className="mt-5 text-bold">
            ESTEJA CIENTE DE QUE ESTES TERMOS DE SERVIÇO REQUEREM O USO DE
            ARBITRAGEM (SEÇÃO 13.4) DE FORMA INDIVIDUAL PARA RESOLVER DISPUTAS,
            EM VEZ DE JULGAMENTOS DE JÚRI OU AÇÕES DE CLASSE, E TAMBÉM LIMITAM
            OS RECURSOS DISPONÍVEIS PARA VOCÊ EM CASO DE DISPUTA.
          </p>
          <p className="mt-5">
            Ao acessar, navegar, enviar informações para e/ou usar o Aplicativo,
            ou ao entrar no Aplicativo, você aceita e concorda em ficar
            vinculado e cumprir estes Termos e nossa Política de Privacidade,
            aqui incorporados por referência, e cumprir todas as disposições
            aplicáveis leis, incluindo, sem limitação, todas as leis,
            regulamentos e/ou diretivas federais, estaduais e locais sobre
            impostos e tarifas. Assim, de acordo com o Artigo 6 do Regulamento
            Geral de Proteção de Dados, ou “GDPR”, os usuários na União Europeia
            reconhecem e consentem em nosso processamento de dados pessoais
            conforme necessário para o desempenho destes Termos, quaisquer
            acordos aplicáveis e uso do Aplicativo. Se você não concordar com os
            Termos, não use o Aplicativo. Os Termos de Serviço são referidos
            aqui como o Acordo."
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 2. ALTERAÇÕES</p>
          <p className="mt-5">
            A Empresa reserva-se o direito de alterar este Contrato, a Política
            de Privacidade da Empresa descrita na Seção abaixo, a qualquer
            momento com notificação razoável, conforme determinado pela Empresa
            a seu exclusivo critério. A Empresa publicará um aviso de qualquer
            alteração no Serviço. Você deve verificar este Contrato e a Política
            de Privacidade da Empresa regularmente para atualizações. Ao
            continuar a usar a Plataforma ou Serviço após tal aviso, você aceita
            e concorda com tais alterações. Se você não concordar com qualquer
            alteração a qualquer um desses acordos, deverá parar de usar a
            Plataforma e o Serviço. Se você tiver alguma dúvida sobre os termos
            e condições deste Contrato ou sobre a Política de Privacidade da
            Empresa, entre em contato conosco em legal@algofame.org
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">3. DEFINIÇÕES E INTERPRETAÇÃO</p>
          <p className="text-bold">3.1. Termos Definidos.</p>
          <p className="mt-5">
            A menos que o contexto exija o contrário, os termos em letras
            maiúsculas neste Contrato terão os seguintes significados:
          </p>
          <p className="mt-3">
            "Afiliada" significa, com relação a uma parte, qualquer pessoa,
            firma, corporação, parceria (incluindo, sem limitação, sociedades
            gerais, sociedades limitadas e sociedades de responsabilidade
            limitada), sociedade de responsabilidade limitada ou outra entidade
            que agora ou no futuro, controla diretamente, é controlado com ou
            por ou está sob controle comum com tal parte.
          </p>
          <p className="mt-3">
            “FAME” significa o APP FAME que fornece serviços e informações de
            contas não custodiais, bem como permite outras atividades que
            ocorrem em uma rede blockchain.
          </p>
          <p className="mt-3">
            “API de Terceiros” significa API de código aberto fornecida por
            terceiros que são utilizados para dar suporte a todos os produtos e
            tecnologias FAME.
          </p>
          <p className="mt-3">
            “Lei Aplicável” significa as leis de Nevis, conforme as mesmas podem
            ser alteradas e entrar em vigor de tempos em tempos durante a
            Vigência.
          </p>
          <p className="mt-3">
            “Dia Útil” significa um dia que não seja sábado, domingo ou outro
            dia em que os bancos comerciais no Reino Unido estejam autorizados
            ou obrigados a fechar.
          </p>
          <p className="mt-3">“NFT” significa Token Não Fungível.</p>
          <p className="mt-3">
            P“Informações de perfil” significa as informações que você nos
            fornece para se registrar no Serviço, incluindo, conforme aplicável,
            o endereço da conta blockchain, nome e endereço, bem como o nome de
            usuário e a senha que permitem que você acesse o Serviço, conforme
            essas informações serão alteradas de tempos em tempos.
          </p>
          <p className="mt-3">
            “Outra Carteira” significa qualquer carteira eletrônica baseada em
            blockchain não FAME, que permite aos Usuários comprar e armazenar
            criptomoedas e assinar/fazer transações em uma blockchain.
          </p>

          <p className="text-bold mt-10"> 3.2. Interpretação.</p>
          <p className="mt-5">
            As referências às Seções e Apêndices devem ser interpretadas como
            referências às Seções e Apêndices deste Contrato, a menos que
            indicado de outra forma. O singular inclui o plural e o plural
            inclui o singular. Todas as referências a este documento, aqui,
            abaixo e outros compostos semelhantes da palavra aqui devem
            significar e referir-se a este Contrato como um todo, em vez de
            qualquer parte específica do mesmo. Os termos incluem e incluindo
            não são limitativos. A menos que designado como Dias Úteis, todas as
            referências a dias significam dias corridos. O uso da palavra
            “incluindo” neste Contrato para se referir a exemplos específicos
            será interpretado como “incluindo, sem limitação” ou “incluindo, mas
            não limitado a” e não será interpretado como significando que os
            exemplos dados são uma lista exclusiva dos temas abordados. Os
            títulos, legendas, cabeçalhos, rodapés e números de versão contidos
            neste Contrato são destinados a conveniência ou referência e não
            devem afetar o significado ou a interpretação deste Contrato.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">4. O SERVIÇO</p>
          <p className="text-bold"> 4.1. Finalidade dos Serviços FAME.</p>
          <p className="mt-5">
            Os serviços FAME são fornecidos com o objetivo de fornecer aos
            usuários uma interface gratuita do lado do cliente que permite aos
            usuários interagir diretamente com o blockchain, mantendo o controle
            total de suas chaves privadas e fundos. O Serviço e a Plataforma não
            se destinam a fornecer nenhum conselho financeiro ou indicar
            qualquer oportunidade de negociação. Qualquer confiança que você
            deposite em tais informações é estritamente por sua conta e risco. A
            Empresa se isenta de qualquer obrigação e responsabilidade
            decorrente de qualquer confiança depositada em tal conteúdo por você
            ou qualquer outro visitante de nosso Aplicativo, ou por qualquer
            pessoa que possa ser informada de qualquer um de seus conteúdos.
            Qualquer informação que você fornecer ou que for coletada pela
            Empresa por meio do Aplicativo será tratada de acordo com a Política
            de Privacidade da Empresa.
          </p>
          <p className="mt-5 text-bold">4.2. Uso do Aplicativo. </p>
          <p className="mt-5">
            A Empresa concede a você uma licença não exclusiva para acessar e
            usar a Plataforma, incluindo o Aplicativo e os dados, materiais,
            conteúdo ou informações aqui contidos (coletivamente, o “Conteúdo”)
            exclusivamente para seu uso pessoal. Seu direito de acessar e usar o
            Aplicativo deve ser limitado aos fins descritos nestes Termos, a
            menos que você seja expressamente autorizado pela Empresa a usar o
            Aplicativo para seus próprios fins comerciais. Você concorda em usar
            o Aplicativo apenas para fins legais, cumprir todas as regras que
            regem quaisquer transações no e por meio do Aplicativo e cumprir as
            leis aplicáveis.
          </p>
          <p className="mt-5 text-bold">
            4.2.1. Responsabilidade da conta do usuário.
          </p>
          <p className="mt-5">
            Ao acessar determinados recursos dos Serviços FAME, você poderá
            criar um FAME e/ou acessar um FAME para realizar diversas
            transações. Você receberá uma chave e definirá uma senha, mas não
            criará e não criará uma conta no FAME. Você também não fornecerá
            acesso aos seus fundos, tokens ou moedas (“fundos”) que não estejam
            no FAME. Todos os fundos estão no próprio blockchain e não os
            controlamos. Nenhum dado sai do seu computador ou navegador. O FAME
            não coleta ou mantém suas chaves ou informações, e o FAME não pode
            acessar contas; recuperar chaves, senhas ou outras informações;
            redefinir senhas; ou transações reversas.
          </p>
          <p className="text-bold">
            VOCÊ É O ÚNICO RESPONSÁVEL PELO SEU USO DO SERVIÇO, INCLUINDO, SEM
            LIMITAÇÃO, ARMAZENAMENTO, BACKUP E MANUTENÇÃO DA CONFIDENCIALIDADE
            DE SUAS CHAVES, SENHAS E INFORMAÇÕES, E PELA SEGURANÇA DE QUAISQUER
            TRANSAÇÕES REALIZADAS USANDO O SITE. VOCÊ ISENTA E ISENTA
            EXPRESSAMENTE A EMPRESA DE TODA E QUALQUER RESPONSABILIDADE E/OU
            PERDA DECORRENTE DO SEU USO DOS SERVIÇOS.
          </p>
          <p className="mt-5">
            Antes de usar o Serviço para qualquer finalidade, recomendamos que
            você leia nossos guias para obter algumas recomendações sobre como
            ser proativo em relação à sua segurança. Além disso, recomendamos
            que você revise as perguntas frequentes, dicas e diretrizes
            adicionais fornecidas em nosso Centro de conhecimento.
          </p>
          <p className="mt-5 text-bold">4.2.2. Considerações Adicionais</p>
          <p className="mt-3 text-bold">
            4.2.2.1. As transações são registradas nas blockchains públicas.
          </p>
          <p className="mt-5 mb-10">
            As transações que ocorrem na plataforma são gerenciadas e
            confirmadas por meio de blockchains públicos, incluindo, entre
            outros, o blockchain da Algorand. O Usuário entende que seu endereço
            público na blockchain relevante será tornado visível publicamente
            sempre que realizar uma transação na Plataforma. Não possuímos nem
            controlamos nenhuma rede blockchain. Optamos por interagir com ou
            qualquer outro site, produto ou serviço de terceiros que os Usuários
            possam acessar, visitar ou usar com a finalidade de permitir que o
            usuário acesse e utilize os vários recursos da Plataforma. Não
            seremos responsáveis pelos atos ou omissões de tais terceiros, nem
            seremos responsáveis por qualquer dano que um Usuário possa sofrer
            como resultado de suas transações ou qualquer outra interação com
            tais terceiros.
          </p>
          <p className="mt-3 text-bold">4.2.2.2. Taxas de transação.</p>
          <p className="mt-5 mb-10">
            Todas as transações na Plataforma são facilitadas por contratos
            inteligentes existentes em uma rede blockchain. As redes Blockchain
            geralmente exigem o pagamento de uma taxa de transação para cada
            transação, por exemplo, a rede Algorand exige o pagamento de uma
            taxa de transação (a “Taxa”) para cada transação que ocorre na rede
            blockchain e, portanto, cada transação que ocorre na a Plataforma
            através de uma rede blockchain. O valor da Taxa pode mudar e está
            totalmente fora do controle da Empresa ou da Plataforma. O usuário
            reconhece que, sob nenhuma circunstância, uma transação na
            plataforma será invalidada, revogável, retrátil ou inexequível com
            base no fato de que a taxa de gás para a transação em questão era
            desconhecida, muito alta ou inaceitável para o usuário.
          </p>
          <p className="mt-3 text-bold">
            4.2.2.3. Nosso uso das informações da conta.
          </p>
          <p className="mt-5 mb-10">
            Os usuários nos autorizam a usar as informações da conta para todos
            os fins relacionados ao Serviço, desde que tal acesso seja usado
            exclusivamente para o propósito de fornecer o Serviço.
          </p>
          <p className="mt-5 text-bold">4.3. Licença. </p>
          <p className="mt-5">
            Sujeito a este Contrato, a Empresa concede a você uma licença
            intransferível, não exclusiva, revogável e limitada para usar e
            acessar o Serviço exclusivamente para seu próprio uso e somente
            conforme permitido e em conformidade com os Termos e a Lei
            Aplicável. Essa licença limitada pode ser revogada a qualquer
            momento, a critério exclusivo da Empresa.
          </p>
          <p className="mt-5 text-bold">4.4. Proibições e Restrições</p>
          <p className="mt-5 text-bold">
            4.4.1. Usos proibidos.
            <br /> Você concorda que não irá:
          </p>
          <p className="mt-5">
            Usar o Aplicativo de qualquer maneira que possa danificar,
            desabilitar, sobrecarregar ou prejudicar o Aplicativo ou interferir
            no uso e gozo de terceiros; Tentar obter acesso não autorizado a
            qualquer conta do Aplicativo, sistemas de computador ou redes
            associadas à Empresa ou ao Aplicativo;
          </p>
          <p className="mt-5">
            Obter ou tentar obter quaisquer materiais ou informações por meio do
            Aplicativo por qualquer meio não intencionalmente disponibilizado ou
            fornecido pela Empresa;
          </p>
          <p className="mt-5">
            Usar qualquer robô, spider ou outro dispositivo, processo ou meio
            automático para acessar o Aplicativo para qualquer finalidade,
            incluindo monitorar ou copiar qualquer material do Aplicativo;
            Introduzir quaisquer vírus, cavalos de Tróia, worms, bombas lógicas
            ou outro material malicioso ou tecnologicamente prejudicial;
          </p>
          <p className="mt-5">
            Atacar o Aplicativo por meio de um ataque de negação de serviço ou
            um ataque distribuído de negação de serviço; ou Representar ou
            tentar representar a Empresa, um funcionário da Empresa, outro
            usuário ou qualquer outra pessoa ou entidade (incluindo, sem
            limitação, usando endereços de e-mail associados a qualquer um dos
            itens anteriores);
          </p>
          <p className="mt-5">
            Licenciar, vender, alugar, arrendar, transferir, ceder, distribuir,
            hospedar ou de outra forma explorar comercialmente o Serviço, no
            todo ou em parte, ou qualquer conteúdo exibido no Serviço;
            Modificar, fazer trabalhos derivados, desmontar, compilar ou fazer
            engenharia reversa de qualquer parte do Serviço; ou
          </p>
          <p className="mt-5">
            Acesse o Serviço para criar um site, produto ou serviço semelhante
            ou competitivo.
          </p>
          <p className="mt-5 text-bold">4.4.2. Restrições.</p>
          <p className="mt-5">
            Exceto conforme expressamente declarado neste documento, nenhuma
            parte do Serviço pode ser copiada, reproduzida, distribuída,
            republicada, baixada, exibida, postada ou transmitida de qualquer
            forma ou por qualquer meio. Salvo indicação em contrário, qualquer
            versão futura, atualização ou outra adição à funcionalidade do
            Serviço estará sujeita a este Contrato. Todos os direitos autorais e
            outros avisos de propriedade no Serviço (ou em qualquer conteúdo
            exibido no Serviço) devem ser mantidos em todas as suas cópias. Você
            não usará o Aplicativo ou Serviço para qualquer finalidade ilegal.
          </p>
          <p className="mt-5 text-bold">4.5. Modificação.</p>
          <p className="mt-5">
            A Empresa se reserva o direito de, a qualquer momento, modificar,
            suspender ou descontinuar o Aplicativo (no todo ou em parte) com ou
            sem aviso prévio. Você concorda que a Empresa não será responsável
            perante você ou terceiros por qualquer modificação, suspensão ou
            descontinuação do Aplicativo ou de qualquer parte dele.
          </p>
          <p className="mt-5 text-bold">4.6. Sem suporte ou manutenção.</p>
          <p className="mt-5">
            Você reconhece e concorda que a Empresa não terá nenhuma obrigação
            de fornecer a você qualquer suporte ou manutenção em conexão com o
            Aplicativo ou Serviço.
          </p>
          <p className="mt-5 text-bold">4.7. Afiliados.</p>
          <p className="mt-5">
            Os direitos, deveres e/ou obrigações da Empresa sob este Contrato
            podem ser exercidos e/ou executados pela Empresa e/ou qualquer uma
            das Afiliadas da Empresa, ou qualquer um de seus subcontratados e/ou
            agentes. A Empresa reconhece e concorda que será a única responsável
            pelos atos ou omissões das Afiliadas da Empresa e de qualquer
            subcontratado ou agente da Empresa ou de qualquer uma das Afiliadas
            da Empresa, relacionados ao assunto aqui tratado. Você concorda que
            qualquer reivindicação ou ação decorrente ou relacionada a qualquer
            ato ou omissão de qualquer Empresa ou Afiliadas da Empresa, ou
            qualquer um de seus respectivos subcontratados ou agentes,
            relacionada ao assunto aqui tratado, deve ser movida apenas contra a
            Empresa, e não contra qualquer uma das Afiliadas da Empresa, ou
            qualquer subcontratado ou agente da Empresa ou qualquer uma das
            Afiliadas da Empresa.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 5. PRIVACIDADE</p>
          <p className="mt-5">
            Você concorda com a Política de Privacidade da Randlabs Company
            (https://randlabs.io/privacy-policy), que é incorporada por
            referência a este Contrato como se fosse aqui estabelecida em sua
            totalidade. A Política de Privacidade descreve como coletamos,
            usamos e divulgamos as informações fornecidas por você. Ao usar o
            Aplicativo ou Serviço, você concorda e está vinculado aos termos da
            Política de Privacidade.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 6. COMUNICAÇÃO COM OS USUÁRIOS</p>
          <p className="mt-5">
            Você afirma que está ciente e reconhece que a Empresa é um provedor
            de serviços sem custódia e projetou esta Plataforma para ser
            acessada diretamente pelos Usuários sem qualquer envolvimento ou
            ações tomadas pela Empresa ou terceiros. A Empresa não tem como se
            comunicar diretamente com os Usuários.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">
            7. LINKS, PRODUTOS E APLICATIVOS DE TERCEIROS
          </p>
          <p className="text-bold">7.1. Sites de terceiros.</p>
          <p className="mt-5">
            O Aplicativo pode conter links para sites controlados ou operados
            por pessoas e empresas que não sejam a Empresa (“Sites Vinculados”),
            incluindo, entre outros, sites relacionados a projetos Web3
            (ocasionalmente com hiperlinks como “Oficiais”), Twitter, Instagram,
            TikTok , Discord, Reddit, Telegram, páginas Foundations e Medium. Os
            Sites Vinculados não estão sob o controle da Empresa, e a Empresa
            não é responsável pelo conteúdo de qualquer Site Vinculado,
            incluindo, sem limitação, qualquer link contido em um Site Vinculado
            ou quaisquer alterações ou atualizações em um Site Vinculado. A
            Empresa não é responsável se o Site Vinculado não estiver
            funcionando corretamente ou por qualquer vírus, malware ou outros
            danos resultantes do uso de um Site Vinculado. A Empresa está
            fornecendo esses links para você apenas como uma conveniência, e a
            inclusão de qualquer link não implica endosso da Empresa ao site ou
            qualquer associação com seus operadores. Você é responsável por
            visualizar e cumprir as políticas de privacidade e os termos de uso
            publicados nos Sites Vinculados. Você é o único responsável por
            quaisquer transações com terceiros que apoiem a Empresa ou sejam
            identificados no Aplicativo, incluindo qualquer entrega e pagamento
            de bens e serviços. A Empresa não armazena nenhuma informação
            compartilhada com um Site Vinculado e não é responsável por nenhuma
            informação de identificação pessoal compartilhada com qualquer Site
            Vinculado.
          </p>
          <p className="text-bold mt-5">7.2. Aplicativos de terceiros.</p>
          <p className="mt-5">
            Você reconhece que seu acesso e uso de qualquer aplicativo ou
            software de terceiros em nosso Aplicativo e Conteúdo, como o
            software de verificação “KYC” Conheça seu cliente (os “Aplicativos
            de terceiros”), fica a seu critério e risco, e o A Empresa não tem
            nenhuma responsabilidade perante você decorrente do seu uso dos
            Aplicativos de Terceiros. A Empresa, por meio deste, se isenta de
            qualquer representação, garantia ou garantia em relação aos
            Aplicativos de terceiros, seja expressa, implícita ou estatutária,
            incluindo, sem limitação, as garantias implícitas de comercialização
            ou adequação a uma finalidade específica e qualquer representação,
            garantia ou garantia em relação à disponibilidade, qualidade,
            confiabilidade, recursos, adequação, precisão, integridade ou
            legalidade dos Aplicativos de terceiros, e você concorda em
            indenizar e isentar a Empresa de quaisquer danos diretos, indiretos,
            punitivos, incidentais, especiais ou consequentes , ou quaisquer
            danos, incluindo, sem limitação, danos por perda de uso, decorrentes
            ou de alguma forma relacionados ao uso ou desempenho dos Aplicativos
            de Terceiros. A Empresa não é responsável por nenhuma informação de
            identificação pessoal compartilhada com quaisquer Aplicativos de
            Terceiros.
          </p>
          <p className="text-bold mt-5">7.3. Liberação.</p>
          <p className="mt-5">
            Você, por meio deste, libera e isenta para sempre a Empresa (e
            nossos executivos, funcionários, agentes, sucessores e cessionários)
            e, por meio deste, renuncia e renuncia a todas e quaisquer disputas,
            reivindicações, controvérsias, demandas, direitos, obrigações,
            responsabilidade, ação e causa de ação de todo tipo e natureza
            (incluindo lesões pessoais, morte e danos à propriedade), que
            surgiram ou surgem direta ou indiretamente, ou que se relacionam
            direta ou indiretamente com o Serviço (incluindo quaisquer
            interações com , ou ato ou omissão de, nossos parceiros ou qualquer
            outro terceiro ou quaisquer links e aplicativos de terceiros).
            <br />
            <span className="text-bold">
              SE VOCÊ É UM RESIDENTE DA CALIFÓRNIA, VOCÊ RENUNCIA A SEÇÃO 1542
              DO CÓDIGO CIVIL DA CALIFÓRNIA EM CONEXÃO COM O PRECEDENTE, QUE
              DECLARA: “UMA LIBERAÇÃO GERAL NÃO SE ESTENDE A REIVINDICAÇÕES QUE
              O CREDOR NÃO SABE OU SUSPEITA QUE EXISTA A SEU FAVOR NO TEMPO DE
              EXECUÇÃO DA LIBERAÇÃO, QUE SE CONHECIDO POR ELE, DEVE TER AFETADO
              substancialmente seu ACORDO COM O DEVEDOR.”
            </span>
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">8. PROPRIEDADE INTELECTUAL</p>
          <p className="text-bold">8.1. Propriedade Intelectual da Empresa.</p>
          <p className="mt-5">
            O usuário reconhece e concorda que a Empresa (ou, conforme
            aplicável, nossos licenciadores) possui todos os direitos legais,
            títulos e interesses em todos os elementos da Plataforma. O logotipo
            da Empresa, gráficos, design, sistemas, métodos, informações, código
            de computador, software, serviços, “aparência”, organização,
            compilação do conteúdo, código, dados e todos os outros elementos da
            Plataforma (coletivamente, o “ Materiais da Empresa”) são de
            propriedade da Empresa. O Aplicativo, a Plataforma, os Materiais da
            Empresa e o Conteúdo são protegidos por direitos autorais, marcas
            registradas ou estão sujeitos a outros direitos de propriedade.
            Consequentemente, você não tem permissão para usar o Aplicativo ou o
            Conteúdo de nenhuma maneira, exceto conforme expressamente permitido
            pela Empresa nestes Termos. O Aplicativo ou Conteúdo não pode ser
            copiado, reproduzido, modificado, publicado, carregado, postado,
            transmitido, executado ou distribuído de nenhuma forma, e você
            concorda em não modificar, alugar, arrendar, emprestar, vender,
            distribuir, transmitir, difundir, ou criar derivados sem o
            consentimento expresso por escrito da Empresa ou do proprietário
            aplicável. Exceto conforme expressamente estabelecido neste
            documento, o uso da Plataforma pelo Usuário não concede ao Usuário a
            propriedade ou quaisquer outros direitos com relação a qualquer
            conteúdo, código, dados ou outros materiais que o Usuário possa
            acessar na ou por meio da Plataforma. A Empresa reserva todos os
            direitos sobre os Materiais da Empresa não expressamente concedidos
            aos Usuários nos Termos. Você não pode usar nenhum Conteúdo da
            Empresa para vincular ao Aplicativo ou Conteúdo sem nossa permissão
            expressa por escrito. Você não pode usar técnicas de enquadramento
            para incluir qualquer Conteúdo da Empresa sem o nosso consentimento
            expresso por escrito. Além disso, a aparência do Site e do Conteúdo,
            incluindo, sem limitação, todos os cabeçalhos de página, gráficos
            personalizados, ícones de botão e scripts constituem a marca de
            serviço, marca registrada ou imagem comercial da Empresa e não podem
            ser copiados, imitados, ou usado, no todo ou em parte, sem nossa
            permissão prévia por escrito.
          </p>
          <p className="mt-5">
            Não obstante qualquer disposição em contrário aqui, você entende e
            concorda que não terá propriedade ou outro interesse de propriedade
            em sua conta e concorda ainda que todos os direitos em e para sua
            conta são e serão para sempre de propriedade e reverterão em
            benefício de a empresa.
          </p>
          <p className="text-bold mt-5">
            8.3. Conformidade com a Lei de Direitos Autorais do Milênio Digital
          </p>
          <p className="text-bold">8.3.1. Notificação.</p>
          <p className="mt-5">
            Levamos a sério as reivindicações de violação de direitos autorais.
            Responderemos a notificações de suposta violação de direitos
            autorais que cumpram a lei aplicável. Se você acredita que qualquer
            material acessível no ou a partir do Aplicativo ou Serviço infringe
            seus direitos autorais, você pode solicitar a remoção desses
            materiais (ou acesso a eles) do Aplicativo enviando uma notificação
            por escrito para Legal@FAMEDigital.com, o aviso por escrito (o "
            "Aviso DMCA"") deve incluir substancialmente o seguinte:
          </p>
          <p className="mt-5">
            uma assinatura física ou eletrônica; identificação do material que
            foi removido ou para o qual o acesso foi desativado e o local em que
            o material apareceu antes de ser removido ou o acesso a ele ser
            desativado; uma declaração sob pena de perjúrio de que o assinante
            acredita de boa fé que o material foi removido ou desativado como
            resultado de erro ou identificação incorreta do material a ser
            removido ou desativado; informações adequadas pelas quais podemos
            entrar em contato com você, incluindo seu nome, endereço e número de
            telefone; e uma declaração de que o assinante concorda com a
            jurisdição de um tribunal distrital federal para o distrito judicial
            em que o endereço está localizado ou, se o endereço do assinante
            estiver fora dos Estados Unidos, para qualquer distrito judicial no
            qual a Empresa possa ser encontrada, e que o assinante aceitará o
            serviço de processo da pessoa que forneceu a notificação sob a
            subseção (c)(1)(C) ou um agente dessa pessoa. A DMCA nos permite
            restaurar o conteúdo removido se a parte que apresentou a
            Notificação DMCA original não entrar com uma ação judicial contra
            você dentro de dez (10) dias úteis após o recebimento da cópia de
            sua contranotificação. Observe que, de acordo com a Seção 512(f) da
            DMCA, qualquer pessoa que conscientemente deturpar materialmente que
            o material ou atividade foi removido ou desativado por engano ou
            identificação incorreta pode estar sujeita a responsabilidade."
          </p>
          <p className="text-bold mt-5">8.3.2. Contranotificação. </p>
          <p className="mt-5">
            Se você optar por enviar ao nosso agente de direitos autorais uma
            contranotificação, para ser eficaz, deve ser uma comunicação por
            escrito que inclua o seguinte (consulte seu advogado ou consulte 17
            U.S.C. Seção 512(g)(3 ) para confirmar esses requisitos):
          </p>
          <p className="mt-5">
            uma assinatura física ou eletrônica; identificação do material que
            foi removido ou para o qual o acesso foi desativado e o local em que
            o material apareceu antes de ser removido ou o acesso a ele ser
            desativado;
          </p>
          <p className="mt-5">
            uma declaração sob pena de perjúrio de que o assinante acredita de
            boa fé que o material foi removido ou desativado como resultado de
            erro ou identificação incorreta do material a ser removido ou
            desativado;
          </p>
          <p className="mt-5">
            informações adequadas pelas quais podemos entrar em contato com
            você, incluindo seu nome, endereço e número de telefone; e
          </p>
          <p className="mt-5">
            uma declaração de que o assinante concorda com a jurisdição de um
            tribunal distrital federal para o distrito judicial em que o
            endereço está localizado ou, se o endereço do assinante estiver fora
            dos Estados Unidos, para qualquer distrito judicial no qual a
            Empresa possa ser encontrada, e que o assinante aceitará o serviço
            de processo da pessoa que forneceu a notificação sob a subseção
            (c)(1)(C) ou um agente dessa pessoa.
          </p>
          <p className="mt-5">
            A DMCA nos permite restaurar o conteúdo removido se a parte que
            apresentou a Notificação DMCA original não entrar com uma ação
            judicial contra você dentro de dez (10) dias úteis após o
            recebimento da cópia de sua contranotificação. Observe que, de
            acordo com a Seção 512(f) da DMCA, qualquer pessoa que
            conscientemente deturpar materialmente que o material ou atividade
            foi removido ou desativado por engano ou identificação incorreta
            pode estar sujeita a responsabilidade.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">9. INDENIZAÇÃO</p>
          <p className="mt-5">
            Você concorda em isentar, indenizar e isentar de responsabilidade a
            Empresa e suas Afiliadas, e seus respectivos executivos, diretores,
            funcionários e agentes, de e contra quaisquer reivindicações,
            responsabilidades, danos, perdas e despesas, incluindo, sem
            limitação, ações legais razoáveis e taxas de contabilidade
            decorrentes ou de alguma forma relacionadas a: (a) seu acesso, uso
            ou incapacidade de usar a Plataforma, o Aplicativo ou o Serviço; (b)
            sua violação deste Contrato; (c) sua violação de quaisquer direitos
            de terceiros; (d) sua violação de qualquer Lei Aplicável; e (e) toda
            e qualquer perda financeira que você possa sofrer, ou fazer com que
            outros sofram, devido à compra, venda, negociação e/ou transferência
            de ALGO, outras criptomoedas, ALGO ou outros ativos digitais, sejam
            essas negociações feitas ou não devido a informações aprendidas na
            Plataforma ou através do Serviço.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">10. ASSUNÇÃO DE RISCO</p>
          <p className="text-bold">
            10.1. O usuário reconhece o risco da criptomoeda e dos contratos
            inteligentes.
          </p>
          <p className="mt-5 text-bold">
            VOCÊ DECLARA E GARANTE QUE ENTENDE E ESTÁ DISPOSTO A ACEITAR OS
            RISCOS ASSOCIADOS A SISTEMAS CRIPTOGRÁFICOS, COMO SMART CONTRACTS,
            REDES PÚBLICAS DE BLOCKCHAIN (INCLUINDO, ENTRE OUTROS, A ALGORAND
            BLOCKCHAIN NETWORK), TOKENS NÃO FUNGÍVEIS E O SISTEMA DE ARQUIVOS
            INTERPLANETÁRIOS.
          </p>
          <p className="text-bold">
            10.2. A empresa não é responsável por erros técnicos em qualquer
            Blockchain.
          </p>
          <p className="mt-5 text-bold">
            A EMPRESA NÃO É RESPONSÁVEL POR PERDAS DEVIDO A BLOCKCHAINS OU
            QUAISQUER OUTROS RECURSOS DA REDE ALGORAND OU QUALQUER OUTRA REDE DE
            BLOCKCHAIN QUE A EMPRESA POSSA FAZER INTERFACE, OU A CARTEIRA
            METAMASK OU QUALQUER NAVEGADOR SIMILAR OU CARTEIRA EM QUALQUER REDE
            BLOCKCHAIN, INCLUINDO, SEM LIMITAÇÃO, RELATÓRIOS ATRASADOS POR
            DESENVOLVEDORES OU REPRESENTANTES (OU NENHUM RELATÓRIO) DE QUAISQUER
            PROBLEMAS COM O BLOCKCHAIN APOIANDO A REDE ALGORAND OU QUALQUER
            OUTRA EMPRESA DE REDE BLOCKCHAIN PODE INTERFACE COM, INCLUINDO
            FORKS, PROBLEMAS DE NÓS TÉCNICOS, OU QUALQUER OUTRO PROBLEMA QUE
            TENHA PERDA DE FUNDO COMO RESULTADO.
          </p>
          <p className="mt-5">
            Atualizações para qualquer rede blockchain com a qual a Empresa
            possa interagir, um hard fork em qualquer plataforma ou uma mudança
            na forma como as transações são confirmadas em qualquer plataforma
            podem ter efeitos adversos não intencionais em todas as blockchains.
          </p>
          <p className="text-bold mt-5">
            10.3. O Usuário Reconhece os Riscos da Plataforma.
          </p>
          <p className="mt-5">
            Você reconhece que a Plataforma está sujeita a falhas e reconhece
            que é o único responsável por avaliar qualquer informação fornecida
            pela Plataforma. Este aviso e outros fornecidos neste Contrato pela
            Empresa de forma alguma evidenciam ou representam um dever contínuo
            de alertá-lo sobre todos os riscos potenciais de utilizar ou acessar
            a Plataforma. A Plataforma pode sofrer ataques cibernéticos
            sofisticados, picos inesperados de atividade ou outras dificuldades
            operacionais ou técnicas que podem causar interrupções ou atrasos na
            Plataforma. Você concorda em aceitar o risco de falha da Plataforma
            resultante de dificuldades técnicas imprevistas ou intensificadas,
            incluindo aquelas resultantes de ataques sofisticados, e você
            concorda em não nos responsabilizar por quaisquer perdas
            relacionadas. A Empresa não assumirá qualquer responsabilidade, seja
            qual for, por quaisquer danos ou interrupções causadas por quaisquer
            vírus que possam afetar seu computador ou outro equipamento, ou
            qualquer phishing, spoofing ou outro ataque.
          </p>
          <p className="text-bold mt-5">
            10.4. A Empresa Não Faz Nenhuma Representação Sobre o Valor de ALGO,
            FAME, Criptomoedas, NFTS ou Outros Ativos Digitais.
          </p>
          <p className="mt-5">
            Os preços dos ativos blockchain são extremamente voláteis.
            Flutuações no preço de outros ativos digitais podem afetar material
            e adversamente o valor da criptomoeda em geral, e NFTs que também
            podem estar sujeitos a volatilidade de preço significativa. A falta
            de uso ou interesse público na criação e desenvolvimento de
            ecossistemas distribuídos pode impactar negativamente o
            desenvolvimento, a utilidade potencial ou o valor dos ativos
            digitais. A Plataforma, NFTs e outros ativos digitais podem ser
            afetados por uma ou mais consultas regulatórias ou ações
            regulatórias. Por todas as razões anteriores, bem como por razões
            que podem não ser atualmente conhecidas pela Empresa, a Empresa não
            faz absolutamente nenhuma representação ou garantia de qualquer tipo
            em relação ao valor de QUALQUER ativo digital.
          </p>
          <p className="text-bold mt-5">
            10.5. O usuário reconhece o risco financeiro dos ativos digitais.
          </p>
          <p className="mt-5">
            O risco de perda na negociação de ativos digitais pode ser
            substancial. Você deve, portanto, considerar cuidadosamente se tal
            criação, compra ou venda de ativos digitais é adequada para você à
            luz de suas circunstâncias e recursos financeiros. Ao usar a
            Plataforma, você declara que foi, é e será o único responsável por
            fazer sua própria avaliação independente. Sob nenhuma circunstância
            a Empresa será responsável em relação ao seu uso da Plataforma em
            relação ao seu desempenho em quaisquer transações de ativos
            digitais. Sob nenhuma circunstância a operação de toda ou qualquer
            parte da Plataforma será considerada como criando um relacionamento
            que inclua o fornecimento ou oferta de consultoria de investimento.
            O Usuário reconhece e concorda que a Empresa não é parte de nenhum
            acordo ou transação entre um ou mais Usuários e/ou terceiros
            envolvendo a compra, venda, cobrança ou transferência de
            criptomoedas, NFTs ou quaisquer outros ativos digitais.
          </p>
          <p className="text-bold mt-5">10.6. Violações por outros usuários.</p>
          <p className="mt-5">
            O usuário irrevogavelmente libera, absolve e isenta para sempre a
            Empresa e suas subsidiárias, afiliadas, executivos e sucessores por
            e contra todas e quaisquer causas passadas ou futuras de ação,
            processos ou controvérsias decorrentes da violação destes Termos por
            outro usuário.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">
            11. LIMITAÇÃO DE RESPONSABILIDADE E ISENÇÃO DE GARANTIA
          </p>
          <p className="text-bold">11.1. Limitação de responsabilidade.</p>
          <p className="text-bold mt-5">
            NA MÁXIMA EXTENSÃO PERMITIDA POR LEI, EM NENHUM CASO A EMPRESA (OU
            NOSSAS AFILIADAS) SERÁ RESPONSÁVEL PERANTE VOCÊ OU QUALQUER TERCEIRO
            POR QUALQUER PERDA FINANCEIRA, PERDA DE LUCROS, PERDA DE DADOS,
            CUSTOS DE AQUISIÇÃO DE PRODUTOS SUBSTITUTOS OU QUALQUER QUALQUER
            INDIRETO, CONSEQUENTE , DANOS EXEMPLARES, INCIDENTAIS, ESPECIAIS OU
            PUNITIVOS DECORRENTES DE OU RELACIONADOS A ESTES TERMOS OU SEU USO
            DE, OU INCAPACIDADE DE USAR, PLATAFORMA, SITE OU SERVIÇO, CONTEÚDO
            OU INFORMAÇÕES ACESSADAS ATRAVÉS DO SITE, OU QUALQUER INTERRUPÇÃO OU
            ATRASO NO DESEMPENHO DO SITE OU DO SERVIÇO MESMO QUE A EMPRESA TENHA
            SIDO AVISADA DA POSSIBILIDADE DE TAIS DANOS. O ACESSO E O USO DOS
            SITES OU SERVIÇOS SÃO POR SEU PRÓPRIO CRITÉRIO E RISCO, E VOCÊ SERÁ
            O ÚNICO RESPONSÁVEL POR QUALQUER DANO AO SEU DISPOSITIVO OU SISTEMA
            DE COMPUTADOR OU PERDA DE DADOS RESULTANTE. ALGUMAS JURISDIÇÕES NÃO
            PERMITEM A LIMITAÇÃO OU EXCLUSÃO DE RESPONSABILIDADE POR DANOS
            INCIDENTAIS OU CONSEQUENTES, PORTANTO, A LIMITAÇÃO OU EXCLUSÃO ACIMA
            PODE NÃO SE APLICAR A VOCÊ.
          </p>
          <p className="text-bold mt-5">11.2. Sem garantias. </p>
          <p className="text-bold mt-5">
            TODAS AS INFORMAÇÕES OU SERVIÇOS FORNECIDOS PELA EMPRESA A VOCÊ
            ATRAVÉS DO WEBSITE, INCLUINDO, SEM LIMITAÇÃO, TODO O CONTEÚDO, SÃO
            FORNECIDOS “COMO ESTÃO” E “ONDE ESTÃO” E SEM QUALQUER GARANTIA DE
            QUALQUER TIPO. A EMPRESA E QUAISQUER TERCEIROS LICENCIADORES COM
            CONTEÚDO NO SITE EXPRESSAMENTE REJEITAM TODAS AS GARANTIAS, SEJAM
            EXPRESSAS, IMPLÍCITAS OU LEGAIS, INCLUINDO, SEM LIMITAÇÃO, AS
            GARANTIAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM DETERMINADO FIM, TÍTULO
            E NÃO VIOLAÇÃO. NÃO OBSTANTE QUALQUER DISPOSIÇÃO CONTIDA AQUI EM
            CONTRÁRIO, A EMPRESA E SEUS TERCEIROS LICENCIANTES NÃO FAZEM NENHUMA
            REPRESENTAÇÃO, GARANTIA OU ACORDO COM RELAÇÃO À PRECISÃO, QUALIDADE,
            ADEQUAÇÃO, COMPLETUDE, SEQUÊNCIA, PONTUALIDADE, SEGURANÇA OU
            DISPONIBILIDADE DO WEBSITE OU QUALQUER CONTEÚDO PUBLICADO EM OU DE
            OUTRA FORMA ACESSÍVEL ATRAVÉS DA PLATAFORMA. VOCÊ RECONHECE
            ESPECIFICAMENTE QUE A EMPRESA E SEUS TERCEIROS LICENCIADORES NÃO SÃO
            RESPONSÁVEIS PELA CONDUTA DIFAMATÓRIA, OBSCENA OU ILEGAL DE OUTROS
            TERCEIROS OU USUÁRIOS DO WEBSITE E QUE O RISCO DE DANOS RESULTANTE
            DO ANTERIOR RESPONSA INTEIRAMENTE A VOCÊ. NEM A EMPRESA NEM QUALQUER
            UM DE SEUS TERCEIROS LICENCIADORES REPRESENTAM, GARANTEM OU CONVÊM
            QUE O SITE SERÁ SEGURO, ININTERRUPTO OU LIVRE DE ERROS. A EMPRESA
            TAMBÉM NÃO GARANTE QUE O WEBSITE ESTARÁ LIVRE DE VÍRUS, WORMS OU
            CAVALOS DE TRÓIA OU QUE FUNCIONARÁ OU OPERARÁ EM CONJUNTO COM
            QUALQUER OUTRO PRODUTO OU SOFTWARE. VOCÊ CONCORDA EXPRESSAMENTE QUE
            O USO DO SITE É POR SUA CONTA E RISCO E QUE A EMPRESA, SUAS
            AFILIADAS NÃO SERÃO RESPONSÁVEIS POR QUALQUER RESCISÃO, INTERRUPÇÃO
            DE SERVIÇO, ATRASOS, ERROS, FALHAS DE DESEMPENHO, DEFEITOS, FALHAS
            DE LINHA OU OMISSÕES ASSOCIADAS AO SITE OU SEU USO DO MESMO. SEU
            ÚNICO RECURSO CONTRA A EMPRESA POR INSATISFAÇÃO COM O SITE OU COM O
            CONTEÚDO É CESSAR O USO DA PLATAFORMA, SITE E/OU SERVIÇO. ALGUMAS
            JURISDIÇÕES NÃO PERMITEM A EXCLUSÃO OU LIMITAÇÃO DE GARANTIAS
            IMPLÍCITAS, PORTANTO, A EXCLUSÃO ACIMA PODE NÃO SE APLICAR A VOCÊ.
            VOCÊ PODE TER OUTROS DIREITOS, QUE VARIAM DE ACORDO COM A
            JURISDIÇÃO. QUANDO NÃO É PERMITIDO QUE AS GARANTIAS IMPLÍCITAS SEJAM
            TOTALMENTE EXCLUÍDAS, VOCÊ CONCORDA QUE ELAS SERÃO LIMITADAS NA
            MAIOR EXTENSÃO E NA MENOR DURAÇÃO PERMITIDAS POR LEI.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">12. PRAZO E RESCISÃO</p>
          <p className="mt-5">
            Sujeito a esta Seção, este Contrato permanecerá em pleno vigor e
            efeito enquanto você usar a Plataforma ou usar o Serviço (o
            “Prazo”). Podemos suspender ou rescindir seus direitos de usar a
            Plataforma ou usar o Serviço a qualquer momento, por qualquer
            motivo, a nosso exclusivo critério, inclusive por qualquer uso da
            Plataforma ou do Serviço que viole este Contrato. Todas as
            disposições do Contrato que, por sua natureza, devam sobreviver,
            sobreviverão ao término do Serviço, incluindo, sem limitação,
            disposições de propriedade, isenções de garantia e limitação de
            responsabilidade.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">13. TERMOS GERAIS</p>
          <p className="text-bold">13.1. Alterações a estes Termos de Uso.</p>
          <p className="mt-5">
            A Empresa pode atualizar ou alterar estes Termos de tempos em tempos
            para refletir alterações em quaisquer serviços oferecidos,
            alterações na lei ou por outros motivos considerados necessários
            pela Empresa. A data efetiva de quaisquer Termos será refletida na
            entrada “Última revisão” na parte superior destes Termos. Seu uso
            continuado do Aplicativo após qualquer alteração ser comunicada
            constituirá seu consentimento para tal(is) alteração(ões).
          </p>
          <p className="text-bold mt-5">13.2. Renúncia. </p>
          <p className="mt-5">
            A renúncia pela Empresa de uma violação de qualquer disposição aqui
            contida deve ser feita por escrito e não deve, de forma alguma, ser
            interpretada como uma renúncia a qualquer violação subsequente de
            tal disposição ou a renúncia da própria disposição.
          </p>
          <p className="text-bold mt-5">13.3. Lei Aplicável e Jurisdição.</p>
          <p className="mt-5">
            TEstes Termos são regidos pelas leis de Nevis. Você concorda
            irrevogavelmente com a jurisdição e foro exclusivos dos tribunais em
            Nevis, em todas as disputas decorrentes ou relacionadas ao uso do
            Aplicativo não sujeitas ao Acordo de Arbitragem descrito em 13.4.
          </p>
          <p className="text-bold mt-5">113.4. Resolução de Disputas.</p>
          <p className="mt-5">
            Por favor, leia o seguinte acordo de arbitragem nesta Seção (“Acordo
            de Arbitragem”) com atenção. Requer que você arbitra disputas com a
            Empresa e limita a maneira pela qual você pode buscar alívio de nós.
            Faz parte do seu contrato com a Empresa e afeta seus direitos.
            Contém procedimentos de VINCULAÇÃO OBRIGATÓRIA ARBITRAGEM E RENÚNCIA
            DE AÇÃO COLETIVA.
          </p>
          <p className="text-bold mt-5">
            13.4.1. Aplicabilidade da convenção de arbitragem.
          </p>
          <p className="mt-5">
            Todas as reivindicações e disputas (excluindo reivindicações de
            liminar ou outra reparação equitativa conforme estabelecido abaixo)
            em conexão com o Contrato ou o uso de qualquer produto ou serviço
            fornecido pela Empresa que não possa ser resolvido informalmente
            serão resolvidos por arbitragem obrigatória em uma base individual
            nos termos desta Convenção de Arbitragem. Salvo acordo em contrário,
            todos os procedimentos de arbitragem serão realizados em inglês.
            Este Acordo de Arbitragem se aplica a você e à Empresa, e a
            quaisquer subsidiárias, Afiliadas, agentes, funcionários,
            predecessores em interesse, sucessores e cessionários, bem como a
            todos os usuários ou beneficiários autorizados ou não autorizados de
            serviços ou bens fornecidos sob o Acordo. Este Acordo de Arbitragem
            se aplicará, sem limitação, a todas as disputas ou reivindicações e
            pedidos de reparação que surgiram ou foram declarados antes da data
            de vigência deste Acordo ou de qualquer versão anterior deste
            Acordo.
          </p>
          <p className="text-bold mt-5">
            13.4.2. Exigência de Notificação e Resolução Informal de Disputas.
          </p>
          <p className="mt-5">
            Antes que qualquer uma das partes possa buscar a arbitragem, a parte
            deve primeiro enviar à outra parte uma Notificação de Disputa por
            escrito (“Aviso”) descrevendo a natureza e a base da reclamação ou
            disputa, e a solução solicitada. Um Aviso à Empresa deve ser enviado
            para:
          </p>
          <p className="mt-3">
            ALGOFAME S.A.S.
            <b /> E-mail: contact@algofame.org
          </p>
          <p className="mt-3">
            Após o recebimento do Aviso, você e a Empresa podem tentar resolver
            a reclamação ou disputa informalmente. Se você e a Empresa não
            resolverem a reclamação ou disputa dentro de trinta (30) dias após o
            recebimento do Aviso, qualquer uma das partes poderá iniciar um
            processo de arbitragem. O valor de qualquer oferta de acordo feita
            por qualquer parte não pode ser divulgado ao árbitro até que o
            árbitro determine o valor da sentença, se houver, ao qual qualquer
            uma das partes tem direito.
          </p>
          <p className="text-bold mt-5">13.4.3. Procedimento de Arbitragem.</p>
          <p className="mt-5">
            Qualquer disputa, reivindicação, interpretação, controvérsia ou
            questões de política pública decorrentes ou relacionadas ao
            Ecossistema FAME, ao Aplicativo, a estes Termos ou aos Serviços,
            incluindo a determinação do escopo ou aplicabilidade desta Seção
            13.4, serão determinados exclusivamente por arbitragem realizada em
            Nevis, e será determinada por arbitragem administrada pelo Centro de
            Conciliação e Arbitragem do Panamá de acordo com suas normas
            processuais. Todos os direitos e obrigações aqui descritos serão
            regidos pelas Leis de Nevis, independentemente dos conflitos de
            provisões legais de tal jurisdição. As Partes se submetem à
            jurisdição não exclusiva dos tribunais de Nevis e quaisquer
            tribunais competentes para ouvir recursos desses tribunais. Para os
            fins desta Seção 13.4, “Processo” significa qualquer reclamação,
            ação judicial, processo, reclamação (incluindo uma reclamação de
            violação da lei aplicável) ou outro processo legal ou equidade, ou
            ordem ou decisão, em cada caso por ou perante qualquer autoridade
            governamental ou tribunal arbitral.
          </p>
          <p className="mt-3">
            As Partes se submetem irrevogavelmente à jurisdição exclusiva dos
            tribunais locais localizados em Nevis com relação a esta Seção 13.4
            para obrigar a arbitragem, para confirmar uma sentença ou ordem de
            arbitragem ou para lidar com funções judiciais permitidas pelos
            Procedimentos de Arbitragem. As Partes renunciam irrevogavelmente à
            defesa de foro inconveniente à manutenção de tal ação ou outro
            Processo.
          </p>
          <p className="text-bold mt-5">
            13.4.4. O árbitro pode conceder medida cautelar, incluindo medida
            cautelar temporária, preliminar, permanente e obrigatória, a fim de
            proteger os direitos de cada parte, mas não se limitará a tal
            medida.
          </p>
          <p className="mt-5">
            Esta disposição para arbitragem não impedirá uma Parte de buscar uma
            medida liminar temporária ou preliminar ("Medida Provisória") em um
            tribunal enquanto o Processo de arbitragem estiver pendente para
            proteger seus direitos pendentes de uma determinação final do
            árbitro, nem o arquivamento de tal ação de Medida Provisória
            constitui renúncia por uma Parte de seu direito de buscar
            arbitragem. Qualquer Medida Provisória concedida por tal tribunal
            permanecerá em vigor até que seja modificada de outra forma pelo
            árbitro.
          </p>
          <p className="text-bold mt-5">
            13.4.5. Dispensa de Julgamento por Júri.
          </p>
          <p className="mt-5">
            AS PARTES RENUNCIAM A SEUS DIREITOS CONSTITUCIONAIS E LEGAIS DE IR
            AO TRIBUNAL E TER UM JULGAMENTO PERANTE UM JUIZ OU JÚRI, optando por
            que todas as reivindicações e disputas sejam resolvidas por
            arbitragem nos termos deste Acordo de Arbitragem. Os procedimentos
            de arbitragem são normalmente mais limitados, mais eficientes e
            menos dispendiosos do que as regras aplicáveis em um tribunal e
            estão sujeitos a revisão muito limitada por um tribunal. No caso de
            qualquer litígio surgir entre você e a Empresa em qualquer tribunal
            em um processo para desocupar ou executar uma sentença arbitral ou
            de outra forma, VOCÊ E A EMPRESA RENUNCIAM A TODOS OS DIREITOS A UM
            JULGAMENTO COM JÚRI, em vez de optar por que a disputa seja
            resolvida por um juiz .
          </p>
          <p className="text-bold mt-5">
            13.4.6. Renúncia de Ações Coletivas ou Consolidadas.
          </p>
          <p className="mt-5">
            TODAS AS REIVINDICAÇÕES E DISPUTAS NO ÂMBITO DESTA CLÁUSULA DEVEM
            SER ARBITRADAS OU LITIGADAS DE FORMA INDIVIDUAL E NÃO DE CLASSE, E
            AS REIVINDICAÇÕES DE MAIS DE UM CLIENTE OU USUÁRIO NÃO PODEM SER
            ARBITRADOS OU LITÍGADOS EM CONJUNTO OU CONSOLIDADOS COM OS DE
            QUALQUER OUTRO CLIENTE OU USUÁRIO.
          </p>
          <p className="text-bold mt-5">
            13.4.7. Direito de 30 dias para optar por não participar.
          </p>
          <p className="mt-5">
            Você tem o direito de cancelar as disposições deste Acordo de
            Arbitragem enviando notificação por escrito de sua decisão de
            desistir dentro de 30 (trinta) dias após ficar sujeito a esta
            Arbitragem Contrato. Sua notificação deve incluir seu nome e
            endereço, endereço da sua carteira e uma declaração inequívoca de
            que você deseja cancelar este Contrato de Arbitragem. Se você
            cancelar este Contrato de Arbitragem, todas as outras partes deste
            Contrato continuarão a se aplicar a você. A recusa deste Acordo de
            Arbitragem não tem efeito sobre quaisquer outros acordos de
            arbitragem que você possa ter atualmente, ou possa celebrar no
            futuro, com a Empresa. Envie sua notificação por escrito por carta
            registrada para:
          </p>
          <p className="mt-3">
            ALGOFAME <br />
            Email: contact@algofame.org
          </p>
          <p className="text-bold mt-5">13.4.8. Confidencialidade. </p>
          <p className="mt-5">
            Todos os aspectos do processo de arbitragem, incluindo, entre
            outros, a decisão do árbitro e seu cumprimento, serão estritamente
            confidenciais. As partes concordam em manter a confidencialidade, a
            menos que exigido por lei. Este parágrafo não impedirá que uma parte
            apresente a um tribunal qualquer informação necessária para fazer
            cumprir este Contrato, para fazer cumprir uma sentença arbitral ou
            para buscar medida cautelar ou equitativa.
          </p>
          <p className="text-bold mt-5">13.4.9. Divisibilidade. </p>
          <p className="mt-5">
            Se qualquer parte ou partes deste Acordo de Arbitragem forem
            consideradas inválidas ou inexequíveis por um tribunal de jurisdição
            competente, tal parte ou partes específicas não terão força e efeito
            e serão cortadas e o restante do Acordo será continuar em pleno
            vigor e efeito.
          </p>
          <p className="text-bold mt-5">13.4.10. Direito de Renúncia. </p>
          <p className="mt-5">
            Qualquer ou todos os direitos e limitações estabelecidos neste
            Acordo de Arbitragem podem ser renunciados pela parte contra a qual
            a reivindicação é feita. Tal renúncia não deve renunciar ou afetar
            qualquer outra parte deste Acordo de Arbitragem.
          </p>
          <p className="text-bold mt-5">13.4.11. Sobrevivência do Acordo. </p>
          <p className="mt-5">
            Este Acordo de Arbitragem sobreviverá ao término de seu
            relacionamento com a Empresa.
          </p>
          <p className="text-bold mt-5">
            13.4.12. Alívio Equitativo de Emergência.{" "}
          </p>
          <p className="mt-5">
            Não obstante o acima exposto, qualquer uma das partes pode buscar
            reparação equitativa de emergência perante um tribunal estadual ou
            federal para manter o status quo pendente de arbitragem. Um pedido
            de medidas provisórias não será considerado uma renúncia a quaisquer
            outros direitos ou obrigações sob este Acordo de Arbitragem.
          </p>
          <p className="text-bold mt-5">
            13.4.13. Reivindicações Não Sujeitas à Arbitragem.
          </p>
          <p className="mt-5">
            Não obstante o acima exposto, reivindicações de difamação, violação
            da Lei de Abuso e Fraude de Computador e violação ou apropriação
            indevida de patente, direitos autorais, marca comercial ou segredos
            comerciais da outra parte não estarão sujeitas a este Acordo de
            Arbitragem.
          </p>
          <p className="text-bold mt-5">13.4.14. Tribunais. </p>
          <p className="mt-5">
            Em quaisquer circunstâncias em que o Acordo de Arbitragem anterior
            permita às partes litigar em tribunal, as partes concordam em
            submeter-se à jurisdição pessoal dos tribunais localizados em Nevis,
            para tal fim.
          </p>
          <p className="text-bold mt-5">
            13.5 Honorários advocatícios e custas.
          </p>
          <p className="mt-5">
            Além de qualquer tutela, ordem ou sentença proferida por um árbitro
            ou tribunal, conforme o caso, qualquer Parte considerada a Parte
            substancialmente vencida em qualquer disputa será obrigada a pagar
            os honorários advocatícios razoáveis e custas de qualquer Parte
            determinada como sendo a Parte substancialmente vencedora, e tal
            Parte perdedora, também deverá reembolsar ou pagar quaisquer
            honorários do árbitro e despesas incorridas pela Parte vencedora em
            qualquer arbitragem. No contexto deste Contrato, honorários
            advocatícios e custos razoáveis devem incluir, mas não se limitar a:
          </p>
          <p className="text-bold mt-5">13.5.1.</p>
          <p className="mt-5">
            honorários e custos legais, honorários e custos de testemunhas,
            contadores, especialistas e outros profissionais, e quaisquer outros
            custos de foro incorridos durante ou em preparação para uma disputa;
          </p>

          <p className="text-bold mt-5">13.5.2. </p>
          <p className="mt-5">
            todos os anteriores incorridos antes ou depois do início de um
            Processo; e
          </p>
          <p className="text-bold mt-5">13.5.3. </p>
          <p className="mt-5">
            todas as taxas e custos incorridos na obtenção de Alívio Provisório.
          </p>
          <p className="mt-5">
            Entende-se que certas entradas de tempo que podem aparecer nos
            registros de cobrança do consultor jurídico de tal Parte podem ser
            redigidas para proteger o privilégio advogado-cliente ou produto de
            trabalho, e isso não impedirá a recuperação das cobranças
            associadas.
          </p>
          <p className="text-bold mt-5">13.6. Terceiros Beneficiários.</p>
          <p className="mt-5">
            Exceto conforme limitado pela Cláusula 13.7, este Contrato e os
            direitos e obrigações aqui estabelecidos deverão vincular e reverter
            em benefício das partes e de seus sucessores e cessionários
            autorizados. Nada neste Contrato, expresso ou implícito, destina-se
            a conferir a qualquer pessoa, exceto as partes e seus sucessores e
            cessionários permitidos, qualquer um dos direitos aqui descritos.
          </p>
          <p className="text-bold mt-5">13.7. Contrato integral. </p>
          <p className="mt-5">
            Este Contrato e cada um de seus anexos ou apêndices constituem e
            contêm o contrato integral entre as partes com relação ao assunto
            aqui tratado e substitui quaisquer acordos verbais ou escritos
            anteriores ou contemporâneos. Cada parte reconhece e concorda que o
            outro não fez nenhuma representação, garantia ou acordo de qualquer
            tipo, exceto conforme expressamente estabelecido neste documento.
          </p>
          <p className="text-bold mt-5">13.8. Divisibilidade. </p>
          <p className="mt-5">
            Se qualquer disposição deste Contrato (ou qualquer parte dele) for
            considerada inválida ou inexequível, as demais disposições deste
            Contrato não serão afetadas por isso e serão vinculativas para as
            partes e serão executáveis, como se a referida disposição inválida
            ou inexequível (ou parte dela) não estava contida neste Contrato.
          </p>
          <p className="text-bold mt-5">13.9. Atribuição.</p>
          <p className="mt-5">
            Você não pode atribuir ou transferir quaisquer direitos aqui
            descritos sem o consentimento prévio por escrito da Empresa. Exceto
            conforme disposto nesta seção, qualquer tentativa que você fizer
            para atribuir qualquer um de seus direitos ou delegar qualquer um de
            seus deveres sem o consentimento prévio por escrito da Empresa será
            nula e sem efeito. A Empresa pode ceder este Contrato ou quaisquer
            direitos aqui contidos sem consentimento.
          </p>
          <p className="mt-5">Informações de contato da empresa.</p>
          <p>Dúvidas podem ser dirigidas à Empresa em contact@algofame.org</p>
        </div>
      </section>
    </>
  );
}
