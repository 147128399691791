import React from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Mix from "../Assets/Img/home.png";
import PhoneGroup from "../Assets/Img/phone-group.png";
import Img4 from "../Assets/Img/image4.png";
import Img5 from "../Assets/Img/image5.png";
import Img6 from "../Assets/Img/image6.png";
import Img10 from "../Assets/Img/image10.png";
import Img11 from "../Assets/Img/image11.png";
import Img12 from "../Assets/Img/image12.png";
import Img13 from "../Assets/Img/image13.png";
import Img16 from "../Assets/Img/image16.png";
import Img17 from "../Assets/Img/image17.png";
import Solution from "../Components/Solution";
import { FadeIn } from "react-slide-fade-in";
import Button from "../Components/Button";
import Page from "../Components/Page";
import GoApp from "../Components/GoApp";
import Form from "../Components/Form";
export default function Home() {
  return (
    <section className="">
      <section>
        <div className="isolate bg-white hero bg-no-repeat ">
          <main className="">
            <div className="">
              <div
                className="p-10 lg:p-20 md:p-20"
                style={{ maxWidth: "1400px", margin: "0 auto" }}
              >
                <div>
                  <div className="pt-20">
                    <FadeIn
                      from="right"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <p className="text-white pt-10 pb-36 lg:pb-0 sm:pb-20 text-right hero-heading">
                        um novo movimento
                      </p>
                    </FadeIn>
                    <FadeIn
                      from="left"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <h6 className="hero-list mt-5 text-right hidden lg:block">
                        <span>Autonomia Alimentar e Empoderamento Mútuo.</span>
                      </h6>
                    </FadeIn>
                    <div className="hidden pt-20 lg:block">
                      <FadeIn
                        from="top"
                        positionOffset={200}
                        triggerOffset={200}
                        delayInMilliseconds={0}
                      >
                        <p className="mt-6 font-normal text-lg text-left leading-8 text-black-600 sm:text-left max-w-lg">
                          FAME é uma plataforma descentralizada da Algorand que
                          conecta trabalhadores, motoristas, agricultores e
                          consumidores em países em desenvolvimento para
                          facilitar transações ponto a ponto. O objetivo é
                          capacitar as comunidades locais e descentralizar a
                          indústria de alimentos.
                        </p>

                        <div className="mt-8 flex gap-x-4 sm:justify-left pt-20">
                          <GoApp />
                          {/* <Button /> */}
                          <Form />
                        </div>
                      </FadeIn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>
      <section className=" lg:hidden p-10 bg-ash ">
        <p className="hero-content">
          FAME é uma plataforma descentralizada da Algorand que conecta
          trabalhadores, motoristas, agricultores e consumidores em países em
          desenvolvimento para facilitar transações ponto a ponto. O objetivo é
          capacitar as comunidades locais e descentralizar a indústria de
          alimentos.
        </p>
        <div className="  mt-5 ">
          <GoApp />
        </div>
        <div className="mt-5 ">{/* <Button /> */}</div>
      </section>
      <section style={{ maxWidth: "1400px", margin: "0 auto" }}>
        <div className="md:grid md:grid-cols-2 text-left sm:grid sm:grid-cols-2 p-10 lg:p-20 md:p-20">
          <div
            style={{
              fontSize: "38px",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            <FadeIn
              from="left"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <p>
                <span className="text-primary">mercado</span> livre.
              </p>
              <p>
                <span className="text-primary">serviços</span> financeiros
                gratuitos.
              </p>
              <p>
                <span className="text-secondary">tecnologia</span> sustentável.
              </p>
            </FadeIn>
            <FadeIn
              from="left"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={300}
            >
              <p
                className="mt-10"
                style={{
                  fontSize: "18px",
                  lineHeight: "32px",
                  fontWeight: "400",
                }}
              >
                O FAME é construído em Algorand, o blockchain mais
                carbono-neutro do mundo.
              </p>
            </FadeIn>
            <FadeIn
              from="left"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={500}
            >
              <img className="mt-5 mb-5" src={Mix} alt="" />
            </FadeIn>
            {/* <div
              className="services object-scale-down pt-20 pb-20"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <img
                className="marketer"
                src={Img1}
                style={{
                  display: "flex",
                  position: "relative",
                  transform: "translate(0,0)",
                  zIndex: "2",
                }}
                alt="Logo"
              />
              <img
                className="farmer"
                src={Img2}
                style={{
                  display: "flex",
                  position: "absolute",
                  zIndex: "3",
                  transform: "translate(80%, 50%)",
                }}
                alt="Logo"
              />
              <img
                className="plain"
                src={Img3}
                style={{
                  display: "flex",
                  position: "absolute",
                  transform: "translate(100%, 0rem)",
                  zIndex: "1",
                }}
                alt=""
              />
            </div> */}
          </div>
          <div className="lg:pl-20">
            <img src={PhoneGroup} alt="" />
            <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-4 lg:gap-1 md:gap-1 ">
              <div className="hidden lg:block md:block">
                <img src={Img4} alt="" />
              </div>
              <div className="col-span-3">
                <p className="leading-8">
                  Torne o aplicativo FAME central para sua vida cotidiana. Use-o
                  para negociar, compartilhar, encontrar trabalho, colaborar,
                  arrecadar fundos, anunciar, ganhar, emprestar, conectar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <section className="bg-[#000000]">
        <div style={{ maxWidth: "1400px", margin: "0 auto" }} className="">
          <div className="lg:grid  lg:grid-cols-2 md:grid-cols-2 ">
            <div className="">
              <img src={Img5} alt="" />
            </div>
            <div className="lg:pt-20 md:pt-20">
              <div
                className=" p-10  lg:p-20 md:p-20 relative"
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <img
                  src={Img6}
                  alt=""
                  className=" absolute bottom-0 right-0 overflow-hidden "
                  style={{
                    position: "relative",
                    display: "flex",
                    transform: "translate(0,0)",
                    zIndex: "0",
                  }}
                />

                <p
                  className="text-white  "
                  style={{
                    fontSize: "38px",
                    fontWeight: "600",
                    lineHeight: "48px",
                    letterSpacing: "0.47256410121917725px",
                    textAlign: "left",
                    position: "absolute",
                    transform: "translate(0,0)",
                    zIndex: "0",
                  }}
                >
                  <FadeIn
                    from="right"
                    positionOffset={200}
                    triggerOffset={200}
                    delayInMilliseconds={0}
                  >
                    <span className="text-secondary ">do nosso jeito!</span>
                    <br /> o aplicativo FAME agora está liberado para testes
                    beta.
                  </FadeIn>

                  <div className="pt-20">
                    <FadeIn
                      from="right"
                      positionOffset={200}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <GoApp />
                    </FadeIn>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="p-10 lg:p-20 md:p-20 text-left"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <div className="grid lg:grid-cols-2 ">
          <div className="lg:pr-20 md:pr-20 lg:pt-0">
            <p
              style={{
                fontSize: "38px",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0.47256410121917725px",
              }}
            >
              <span className="text-secondary">
                trabalhar. vender. emprestar. ganhar.
              </span>
              <span className="text-primary"> CONECTAR.</span>
            </p>
            <p
              className="leading-8 pt-8 lg:pt-10 "
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "31px",
                letterSpacing: "0.44999998807907104px",
                textAlign: "left",
              }}
            >
              Todo usuário do FAME pode abrir imediatamente uma conta poupança
              de forma não custodial para ter acesso ao microcrédito em poucos
              passos simples.
            </p>
          </div>

          <div className=" lg:pl-10 md:pl-10 pt-10 lg:pt-0">
            <div>
              <p
                className="text-left"
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "31px",
                  letterSpacing: "0.44999998807907104px",
                }}
              >
                Em FAME, acreditamos que podemos empoderar pequenos agricultores
                com um mercado social feito sob medida para alimentos e
                empregos; reduzindo a insegurança alimentar, melhorando as taxas
                de emprego e ativando a inclusão financeira.
              </p>
              <p
                className="pt-10"
                style={{
                  fontSize: "38px",
                  fontWeight: "600",
                  lineHeight: "48px",
                  letterSpacing: "0.47256410121917725px",
                }}
              >
                <span className="text-primary">Junte-se a nós</span> para
                aproveitar os benefícios do
                <span className="text-secondary"> DEFI!</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Solution />
      <section style={{ maxWidth: "1400px", margin: "0 auto" }}>
        <div className="lg:grid lg:grid-cols-2">
          <div>
            <FadeIn
              from="left"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <div>
                <div className="grid grid-cols-3 gap-10 p-10 pb-0 lg:p-20 md:p-20">
                  <div className="col-span-1">
                    <img src={Img10} alt="" />
                  </div>
                  <div className="col-span-2">
                    <p
                      className="mb-5 mt-3 lg:mt-0 md:mt-0"
                      style={{
                        fontSize: "23px",
                        fontWeight: "600",
                        lineHeight: "28px",
                        letterSpacing: "0.574999988079071px",
                        textAlign: "left",
                      }}
                    >
                      CONECTAR: aplicativo social
                    </p>
                    <p
                      className="lg:block hidden"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "27px",
                        letterSpacing: "0.38749998807907104px",
                        textAlign: "left",
                      }}
                    >
                      O aplicativo FAME permite que agricultores, entregadores,
                      clientes e fornecedores se comuniquem, colaborem, façam
                      transações e confiem uns nos outros.
                    </p>
                  </div>
                </div>
                <p
                  className="lg:hidden block p-10 pt-0 "
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "27px",
                    letterSpacing: "0.38749998807907104px",
                    textAlign: "left",
                  }}
                >
                  O aplicativo FAME permite que agricultores, entregadores,
                  clientes e fornecedores se comuniquem, colaborem, façam
                  transações e confiem uns nos outros.
                </p>
              </div>
            </FadeIn>
            <FadeIn
              from="left"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <div>
                <div className="grid grid-cols-3 gap-10 p-10 pb-0 lg:p-20 md:p-20">
                  <div className="col-span-1">
                    <img src={Img11} alt="" />
                  </div>
                  <div className="col-span-2">
                    <p
                      className="mb-5 mt-3 lg:mt-0 md:mt-0"
                      style={{
                        fontSize: "23px",
                        fontWeight: "600",
                        lineHeight: "28px",
                        letterSpacing: "0.574999988079071px",
                        textAlign: "left",
                      }}
                    >
                      PROVE: Certificados NFT
                    </p>
                    <p
                      className="lg:block hidden"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "27px",
                        letterSpacing: "0.38749998807907104px",
                        textAlign: "left",
                      }}
                    >
                      Tokens não fungíveis (NFTs) podem ser usados para
                      estabelecer contratos, acordos e certificados de
                      propriedade para partes interessadas locais defenderem sua
                      propriedade.
                    </p>
                  </div>
                </div>
                <p
                  className="lg:hidden block p-10 pt-0"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "27px",
                    letterSpacing: "0.38749998807907104px",
                    textAlign: "left",
                  }}
                >
                  Tokens não fungíveis (NFTs) podem ser usados para estabelecer
                  contratos, acordos e certificados de propriedade para partes
                  interessadas locais defenderem sua propriedade.
                </p>
              </div>
            </FadeIn>
          </div>

          <div>
            <FadeIn
              from="right"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <div>
                <div className="grid grid-cols-3 gap-10 p-10 pb-0 lg:p-20 md:p-20">
                  <div className="col-span-1">
                    <img src={Img12} alt="" />
                  </div>
                  <div className="col-span-2">
                    <p
                      className="mb-5 mt-3 lg:mt-0 md:mt-0"
                      style={{
                        fontSize: "23px",
                        fontWeight: "600",
                        lineHeight: "28px",
                        letterSpacing: "0.574999988079071px",
                        textAlign: "left",
                      }}
                    >
                      COMÉRCIO: Mercado
                    </p>
                    <p
                      className="lg:block hidden"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "27px",
                        letterSpacing: "0.38749998807907104px",
                        textAlign: "left",
                      }}
                    >
                      Fornece um mercado social para permitir que os
                      agricultores anunciem e vendam seus produtos diretamente,
                      publiquem ofertas de emprego em sua área local e para que
                      os trabalhadores explorem novas oportunidades.
                    </p>
                  </div>
                </div>
                <p
                  className="lg:hidden block p-10 pt-0"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "27px",
                    letterSpacing: "0.38749998807907104px",
                    textAlign: "left",
                  }}
                >
                  Fornece um mercado social para permitir que os agricultores
                  anunciem e vendam seus produtos diretamente, publiquem ofertas
                  de emprego em sua área local e para que os trabalhadores
                  explorem novas oportunidades.
                </p>
              </div>
            </FadeIn>
            <FadeIn
              from="right"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <div>
                <div className="grid grid-cols-3 gap-10 p-10 pb-0 lg:p-20 md:p-20 lg:pt-14 md:pt-14">
                  <div className="col-span-1">
                    <img src={Img13} alt="" />
                  </div>
                  <div className="col-span-2">
                    <p
                      className="mb-5 mt-3 lg:mt-0 md:mt-0"
                      style={{
                        fontSize: "23px",
                        fontWeight: "600",
                        lineHeight: "28px",
                        letterSpacing: "0.574999988079071px",
                        textAlign: "left",
                      }}
                    >
                      INVESTIR: Acesso ao DEFI
                    </p>
                    <p
                      className="lg:block hidden"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "27px",
                        letterSpacing: "0.38749998807907104px",
                        textAlign: "left",
                      }}
                    >
                      Trabalhadores, motoristas, agricultores e consumidores
                      podem ter acesso direto e fácil aos serviços financeiros
                      mais avançados que existem na Algorand Blockchain.
                    </p>
                  </div>
                </div>
                <p
                  className="lg:hidden block p-10 pt-0"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "27px",
                    letterSpacing: "0.38749998807907104px",
                    textAlign: "left",
                  }}
                >
                  Trabalhadores, motoristas, agricultores e consumidores podem
                  ter acesso direto e fácil aos serviços financeiros mais
                  avançados que existem na Algorand Blockchain.
                </p>
              </div>
            </FadeIn>
          </div>
        </div>
      </section>
      <section className="bg-[#F7F7F7] ">
        <div
          className="lg:grid lg:grid-cols-10 text-left p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <div className=" col-span-6 lg:pr-20">
            <p
              className="text-primary"
              style={{
                fontSize: "38px",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0.47256410121917725px",
              }}
            >
              Fundação FAME
            </p>
            <p
              className="pt-10 mr-5"
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "31px",
                letterSpacing: "0.44999998807907104px",
              }}
            >
              Fundação FAMA Um dos principais objetivos da FAME Foundation é
              expandir o acesso a smartphones em áreas rurais, trazendo
              infraestrutura FAME e a tecnologia blockchain com suas muitas
              possibilidades diretamente para agricultores, trabalhadores,
              empresas e consumidores. Vamos trabalhar em conjunto com outros
              projetos/iniciativas/artistas para trazer smartphones para estas
              áreas, tornando o FAME Marketplace acessível onde os agricultores
              podem vender os seus produtos e os trabalhadores podem ter acesso
              a mais oportunidades de trabalho.
            </p>
            <Page />
          </div>
          <div className="col-span-4">
            <img src={Img16} alt="" />
          </div>
        </div>
      </section>
      <section className="" style={{ maxWidth: "1400px", margin: "0 auto" }}>
        <div
          className="lg:grid lg:grid-cols-10 text-left p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <div className="col-span-3 lg:pt-20 md:pt-20 pt-0">
            <img src={Img17} alt="" />
          </div>
          <div className="col-span-7 pt-10 lg:p-20  ">
            <p
              className="text-primary"
              style={{
                fontSize: "38px",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0.47256410121917725px",
              }}
            >
              Instituto FAME
            </p>
            <p
              className="pt-10"
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "31px",
                letterSpacing: "0.44999998807907104px",
              }}
            >
              É mudando pequenas comunidades que mudamos o mundo inteiro,
              tornando-o um lugar melhor para todos. O Instituto FAME é uma
              iniciativa que reunirá esforços tecnológicos, financeiros, sociais
              e educacionais para apoiar as comunidades locais. Integrando
              estudantes, cientistas e técnicos de diferentes formações
              acadêmicas que unirão forças para construir um futuro justo e
              equitativo, usando seus conhecimentos para conectar o mundo real e
              a tecnologia blockchain.
            </p>
            <Page />
          </div>
        </div>
      </section>
    </section>
  );
}
