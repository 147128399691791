import React from "react";
import Img8 from "../Assets/Img/image8.png";
export default function ProblemBox() {
  return (
    <div className="relative flex">
      <p
        className="flex uppercase text-white absolute"
        style={{
          fontSize: "22px",
          fontWeight: "400",
          lineHeight: "28px",
          letterSpacing: "0.550000011920929px",
          textAlign: "left",
          zIndex: "1",
          transform: "translate(70px, 18px)",
        }}
      >
        O PROBLEMA
      </p>
      <img
        src={Img8}
        alt="Problem"
        className="relative flex"
        style={{ zIndex: "0" }}
      />
    </div>
  );
}
