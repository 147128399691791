import React, { useState } from "react";

import Logo from "../Assets/Img/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { BsTriangleFill } from "react-icons/bs";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { useEffect } from "react";
import Flag from "react-country-flags";

export default function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [colorChange, setColorchange] = useState(false);
  const [navLogo, setNavLogoChange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 100) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  const changeLogoSize = () => {
    if (window.scrollY >= 100) {
      setNavLogoChange(true);
    } else {
      setNavLogoChange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);
  window.addEventListener("scroll", changeLogoSize);


  return (
    <>
       <IconContext.Provider value={{ color: "#" }}>
        <nav className={colorChange ? "navbar colorChange" : "navbar"}>
          <div
            className="navbar-container container lg:pl-20 lg:pr-20"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            <div className={navLogo ? " navLogo" : "navbar-logo"}>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>
            <div className="flag lg:hidden">
              <a href="https://algofame.org" className="flag">
                <Flag
                  country="gb"
                  asSquare={false}
                  style={{ height: "20px", marginTop: "5px" }}
                />
              </a>
            </div>
            <div className="menu-icon text-white" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <div className="triangle lg:hidden">
                <BsTriangleFill />
              </div>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Página Inicial<span className="dot">.</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Sobre<span className="dot">.</span>
                </NavLink>
              </li>
              <li className="nav-item lg:block hidden">
                <a className="nav-links" href="https://algofame.org">
                  <Flag
                    country="gb"
                    asSquare={false}
                    style={{ height: "20px" }}
                  />
                </a>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to="/foundation"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Fundação<span className="dot">.</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/institute"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                 Instituto<span className="dot">.</span>
                </NavLink>
              </li> */}

              <li id="google_translate_element"></li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}
