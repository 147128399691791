import React from "react";

export default function Error() {
  return (
    <>
      <div style={{ height: "100vh" }} className="bg-secondary">
        <h1 style={{ paddingTop: "200px" }}>Oops! You have hit an error</h1>
      </div>
    </>
  );
}
