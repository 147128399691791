import React from "react";
import "react-responsive-modal/styles.css";

import Playstore from "../Assets/Img/playstore.png";

export default function GoApp() {
  return (
    <>
      <a
        href="https://play.google.com/store/apps/details?id=com.fame.app"
        target="_blank"
        rel="noreferrer"
        className="inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg bg-white px-4 py-1.5 text-base font-semibold leading-7 text-primary shadow-sm"
      >
         <img src={Playstore} alt="" width={27} />
         <span className="ml-3">BAIXE O APLICATIVO FAME</span>
      </a>
    </>
  );
}
