import React from "react";
import { FadeIn } from "react-slide-fade-in";
import Img14 from "../Assets/Img/image14.png";
import Img1 from "../Assets/Img/image1.png";
import ProblemBox from "../Components/ProblemBox";
import SolutionBox from "../Components/SolutionBox";
import Roadmap from "../Assets/Img/roadmap.png";
import RoadmapMobile from "../Assets/Img/roadmap-mobile.png";
import Partner1 from "../Assets/Img/partner1.png";
import Partner2 from "../Assets/Img/partner2.png";
import Partner3 from "../Assets/Img/partner3.png";
import Partner4 from "../Assets/Img/partner4.png";
import Partner5 from "../Assets/Img/partner5.png";
import Partner6 from "../Assets/Img/partner6.png";
import Person1 from "../Assets/Img/person1.png";
import Person2 from "../Assets/Img/person2.png";
import Person3 from "../Assets/Img/person3.png";
import Person4 from "../Assets/Img/person4.png";
import Person5 from "../Assets/Img/person5.png";
import Person6 from "../Assets/Img/person6.png";
import Person81 from "../Assets/Img/person81.png";
import Person9 from "../Assets/Img/person9.png";
import Person10 from "../Assets/Img/person10.png";
import Person11 from "../Assets/Img/person11.png";
import Person12 from "../Assets/Img/person12.png";
import Person13 from "../Assets/Img/person13.png";
import Person14 from "../Assets/Img/person14.png";
import Person15 from "../Assets/Img/person15.png";
import Person16 from "../Assets/Img/person16.png";
import Img19 from "../Assets/Img/image19.png";
import Team from "../Components/Team";

export default function About() {
  return (
    <section>
      <section>
        <div
          className="isolate bg-white hero-about lg:bg-top md:bg-top bg-[url('./Assets/Img/about-bg.png')] bg-left-top"
          style={{ backgroundRepeat: "no-repeat" }}
        >
          <main className="" style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="">
              <div className="p-10 lg:p-20 md:p-20 ">
                <div>
                  <div className="pt-20">
                    <FadeIn
                      from="left"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <p className="text-white lg:pb-0 md:pb-0 pb-20 lg:pt-0 md:pb-0 pt-10 text-right hero-heading">
                        quem nós somos.
                      </p>
                    </FadeIn>
                    <FadeIn
                      from="right"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <h6 className="hero-list mt-10 text-right text-white hidden md:block lg:block">
                        <span>
                          trabalhar. vender. emprestar. ganhar. CONECTAR.
                        </span>
                      </h6>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>
      <section
        className="lg:p-20 p-10 grid lg:grid-cols-2 md:grid-cols-2 gap-5"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <FadeIn
          from="left"
          positionOffset={400}
          triggerOffset={200}
          delayInMilliseconds={0}
        >
          <div className="pb-10 lg:pr-5 md:pr-5">
            <p className="text-bold">
              FAME é uma iniciativa de Finanças Regenerativas (ReFi -
              Regenerative Finance)
            </p>
            <p className="text-thin">
              Uma nova abordagem para resolver problemas sistêmicos na cadeia
              alimentar e regenerar as comunidades ao seu redor. FAME visa tirar
              indivíduos do modelo tradicional de exploração para um ecossistema
              Web3 descentralizado que fornece autonomia, poder financeiro,
              controle, retorno justo e devido reconhecimento.
            </p>
          </div>
        </FadeIn>
        <FadeIn
          from="right"
          positionOffset={400}
          triggerOffset={200}
          delayInMilliseconds={0}
        >
          <div className="pb-10 lg:pl-5 md:pl-5">
            <p className="text-bold">FAME fornece um Mercado Social</p>
            <p className="text-thin">
              Capacita agricultores, trabalhadores e consumidores. Por meio da
              tecnologia e do acesso financeiro, o objetivo é permitir que os
              agricultores cresçam e saiam da pobreza, tornando-os “famosos” por
              seus produtos, que se tornam diretamente acessíveis aos
              consumidores, que também se beneficiam de produtos de melhor
              qualidade e preços justos.
            </p>
          </div>
        </FadeIn>
      </section>
      <section
        style={{
          background:
            "linear-gradient(63.54deg, #6E0289 16.59%, #00C3B3 88.13%)",
        }}
      >
        <div
          className="lg:p-20 p-10 grid lg:grid-cols-7 gap-10"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <img className="col-span-3" src={Img14} alt="" />
          <div className="col-span-2 pb-10">
            <ProblemBox />
            <p className="text-thin text-white mt-5">
              Os países em desenvolvimento enfrentam a marginalização social e a
              exclusão financeira levando à pobreza e à insegurança alimentar e,
              juntamente com o resto do mundo, são reféns dos efeitos das
              Mudanças Climáticas que culminam na degradação dos recursos
              naturais, perda da biodiversidade e escassez de recursos.
            </p>
          </div>
          <div className="col-span-2">
            <SolutionBox />
            <p className="text-thin text-white mt-5 ">
              Mostrar serviços e produtos para apoiar a comunidade africana de
              trabalhadores, motoristas, agricultores e consumidores a colaborar
              sem intermediários. Acesso a serviços financeiros avançados em
              escala (empréstimos, empréstimos) construindo uma “classe média”
              de vários milhões de pessoas.
            </p>
          </div>
        </div>
      </section>
      <section
        className="lg:p-20 p-10"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          <span className="text-secondary">Roteiro </span> do desenvolvimento do
          projeto
        </p>
        <img className="pt-20 lg:block md:block hidden" src={Roadmap} alt="" />
        <img
          className="pt-20 md:hidden lg:hidden sm:block"
          src={RoadmapMobile}
          alt=""
        />
      </section>
      <section
        style={{
          background:
            "linear-gradient(63.54deg, #6E0289 16.59%, #00C3B3 88.13%)",
        }}
      >
        <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
          <div className="grid lg:grid-cols-3  gap-10 p-10 lg:p-20 md:p-20 content-center text-white">
            <div className="text-left">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <ProblemBox />
                <p
                  className="mt-5 mb-5 md:mr-20 lg:mr-20 "
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    lineHeight: "33px",
                    letterSpacing: "0.6499999761581421px",
                    textAlign: "left",
                    color: "#595959;",
                  }}
                >
                  Como consumidora, Ana não:
                </p>
                <div className="text-left inline-block mb-3">
                  <span class="red-dot"></span>
                  <span className=" mt-0 ">
                    Ana não encontra produtos baratos, saudáveis, orgânicos e
                    éticos perto de sua casa e pode estar sujeito à desnutrição
                    com seus filhos.
                  </span>
                </div>
                <div className="text-left inline-block mb-3">
                  <span class="red-dot"></span>
                  <span className=" mt-0">
                    Os mercados são limitados em sua área e a qualidade dos
                    alimentos não é garantida.
                  </span>
                </div>
                <div className="text-left inline-block">
                  <span class="red-dot"></span>
                  <span className=" mt-0">
                    Por causa de sua baixa renda, ele não consegue um
                    microcrédito.
                  </span>
                </div>
              </FadeIn>
            </div>
            <div className="text-left">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <SolutionBox />
                <p
                  className=" mt-5 mb-5 md:mr-20 lg:mr-20"
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    lineHeight: "33px",
                    letterSpacing: "0.6499999761581421px",
                    textAlign: "left",
                  }}
                >
                  Com o FAME Ana pode agora:
                </p>

                <div className="text-left inline-block mb-3">
                  <span class="green-dot"></span>
                  <span className=" mt-0">
                    Obtenha um preço melhor e comida de maior qualidade dos
                    agricultores locais que ele gosta e siga no aplicativo FAMA.
                  </span>
                </div>
                <div className="text-left inline-block mb-3">
                  <span class="green-dot"></span>
                  <span className=" mt-0">
                    Contribuir para o crescimento de sua comunidade local de
                    trabalhadores e agricultores e economizando ao mesmo tempo.
                  </span>
                </div>
                <div className="text-left inline-block">
                  <span class="green-dot"></span>
                  <span className=" mt-0">
                    Aproveitar finanças descentralizadas (DEFI) da Algorand para
                    obter uma renda passiva e ter acesso a fundos
                    automaticamente.
                  </span>
                </div>
              </FadeIn>
            </div>
            <div>
              <FadeIn
                from="right"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <img
                  className="content-center"
                  src={Img19}
                  alt=""
                  style={{
                    borderWidth: "9px",
                    borderColor: "#ffffff",
                    borderRadius: "100%",
                  }}
                />
              </FadeIn>
            </div>
          </div>
        </div>
      </section>
      <section className="solution content-center">
        <div
          style={{ maxWidth: "1400px", margin: "0 auto" }}
          className="grid lg:grid-cols-3 gap-10 p-10 lg:p-20 md:p-20"
        >
          <div>
            <FadeIn
              from="left"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <img
                className="content-center drop-shadow-[10px_10px_36px_rgba(0,0,0,0.306053)]"
                src={Img1}
                alt=""
                style={{
                  borderWidth: "9px",
                  borderColor: "#ffffff",
                  borderRadius: "100%",
                }}
              />
            </FadeIn>
          </div>
          <div className="text-left">
            <FadeIn
              from="right"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <ProblemBox />
              <p
                className="text-white mt-5 mb-5 md:mr-20 lg:mr-20 solution-heading"
                style={{
                  fontSize: "26px",
                  fontWeight: "600",
                  lineHeight: "33px",
                  letterSpacing: "0.6499999761581421px",
                  textAlign: "left",
                }}
              >
                Emanuel não conseguiu encontrar trabalhos de entrega, então:
              </p>
              <div className="text-left inline-block mb-3">
                <span class="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Ele contou com amigos e familiares para encontrar novos
                  clientes.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span class="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Ele esperou por horas em frente de lojas esperando por
                  trabalho.
                </span>
              </div>
              <div className="text-left inline-block">
                <span class="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Ele não tinha controle sobre seu salário ou contrato e
                  dependia de organizações centralizadas como Uber.
                </span>
              </div>
            </FadeIn>
          </div>
          <div className="text-left ">
            <FadeIn
              from="right"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <SolutionBox />
              <p
                className="text-white mt-5 mb-5 md:mr-20 lg:mr-20 solution-heading"
                style={{
                  fontSize: "26px",
                  fontWeight: "600",
                  lineHeight: "33px",
                  letterSpacing: "0.6499999761581421px",
                  textAlign: "left",
                }}
              >
                Com FAME Emanuel pode agora:
              </p>

              <div className="text-left inline-block mb-3">
                <span class="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Encontrar as melhores oportunidades de trabalho.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span class="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Aceitar trabalhos quando quiser e de forma flexível, estando
                  no controle de como gasta seu tempo.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span class="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Capaz de negociar melhores negócios diretamente com
                  agricultores, restaurantes e consumidores, construindo
                  confiança.
                </span>
              </div>
            </FadeIn>
          </div>
        </div>
      </section>
      <section className="bg-secondary ">
        <div
          style={{ maxWidth: "1400px", margin: "0 auto" }}
          className=" p-10 lg:p-20 md:p-20"
        >
          <p className="partner-text col-span-3">
            Organizações <br />
            <span className="text-white"> parceiras</span>
          </p>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-10 pt-10">
            <div className="">
              <img src={Partner1} alt="" className="image" />
              <p className="subtitle mt-3">Algorand Foundation</p>
            </div>
            <div className="">
              <img src={Partner2} className="image" alt="" />
              <p className="subtitle mt-3">
                Blockchain Supply Chain Association - Global
              </p>
            </div>
            <div className="">
              <img src={Partner3} alt="" className="image" />
              <p className="subtitle mt-3">Algo Foundry</p>
            </div>
            <div className="">
              <img src={Partner4} alt="" className="image" />
              <p className="subtitle mt-3">LE 101</p>
            </div>
            <div className="">
              <img src={Partner5} alt="" className="image" />
              <p className="subtitle mt-3">Hôtel Entreprises</p>
            </div>
            <div className="">
              <img src={Partner6} alt="" className="image" />
              <p className="subtitle mt-3">Fabrique de Territoire</p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-10 lg:p-20 md:p-20"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          a <span className="text-primary">equipe</span> fame
        </p>
        <div className="grid lg:grid-cols-3 pt-10 gap-10">
          <Team
            person="Bilal Brahim"
            position="CEO"
            picture={Person1}
            content1="Bilal formou-se como filósofo na Universidade de Sorbonne e ensinou ética e filosofia metafísica por mais de 13 anos na França e na Austrália."
            content2="Em 2019, ele descobriu o trabalho de Silvio Micali e o quão impactante isso poderia ser para a inclusão financeira em todo o mundo, então ele mergulhou na tecnologia da Algorand para se tornar um embaixador e um líder comunitário."
            content3="Em 2021, ele decidiu lançar seu próprio projeto ESG na Algorand, chamado FAME, para alavancar a tecnologia da Algorand para melhorar a inclusão financeira de agricultores em países em desenvolvimento."
          />
          <Team
            person="Tim O'Brien"
            position="CTO"
            picture={Person13}
            content1="Tim tem 20 anos de experiência como consultor de arquitetura para empresas da Fortune 500 e também fundou 4 empresas. Desde 2018, Tim vem construindo em blockchain e perseguindo sua paixão para democratizar a tecnologia e garantir que qualquer pessoa possa construir blockchain e aplicativos de cadeia de suprimentos, independentemente de sua formação, proficiência técnica ou onde nasceram."
            content2=""
            content3=""
          />
          <Team
            person="Kingsley Chukwuonyia"
            position="FAME Proprietário da Fazenda, Fundação Fame (Lagos)"
            picture={Person4}
            content1="Kingsley Chukwuonyia é químico, formado pela Universidade de ciência e tecnologia em enugu, estado da Nigéria, ESUT, com experiencia de trabalho em controle de qualidade e telecomunicações."
            content2="Atualmente é fazendeiro no estado de Akure Ondo e fundador da fama de Kingsley Fundação."
            content3=""
          />
        
          <Team
            person="Emmanuel Charles"
            position="Desenvolvedor Blockchain "
            picture={Person6}
            content1="Emmanuel Charles é um engenheiro de design certificado e desenvolvedor de software com 4 anos de experiência na criação de produtos utilizáveis e escaláveis em SAAP, SAAS, WEB2/WEB3, Mobile Solution e outras empresas para acelerar seu crescimento."
            content2="As habilidades de programação de Emmanuel incluem Java, Reach Blockchain, Dart(Flutter), Python(Automation) e JavaScript(Native) altamente qualificado em solução de Design, desenvolvimento de aplicativos móveis, visualização de dados, desenvolvimento de Blockchain, hacking ético e automação de hardware, atualmente trabalhando com ALGOFAME, ele mora em Lagos, Nigéria."
            content3=""
          />
          <Team
            person="Abdulrazaq Oladapo"
            position="Desenvolvedor Full Stack"
            picture={Person81}
            content1="Abdulrazaq é Engenheiro Mecatrônico e desenvolvedor de software sênior com mais de 6 anos de experiência e atualmente trabalha ao lado da equipe executiva da AlgoFAME."
            content2="Abdulrazaq é especialista em tecnologia de desenvolvimento de aplicativos móveis (Flutter) e é responsável pela construção da solução social para agricultores FAME web3 e também um entusiasta ativo da blockchain ALGORAND."
            content3="Abdulrazaq tem muitas habilidades de programação de computador que incluem Javascript (NodeJs), Dart(Flutter), C++(IOT) e Java(Native) e construiu vários aplicativos nessas linguagens de plataforma. Ele atualmente mora na Nigéria, no estado de Ekiti."
          />
          <Team
            person="Aholu Samuel"
            position="Desenvolvedor Blockchain"
            picture={Person9}
            content1="Samuel é um desenvolvedor da Web com mais de 4 anos de experiência, que contribui especialmente em otimização de mecanismo de pesquisa - para lançar código personalizado para a presença do site, compreensão detalhada da abordagem prática para personalizar a estratégia de presença na web. Navegação engenhosa de fatores intrincados que regem o planejamento da web, segurança, hospedagem e SEO."
            content2="As habilidades de programação de Samuel incluem CSS, JavaScript (Vanilla, React & Nodejs), PHP (Laravel), C++ (IOT). Ele atualmente mora no estado de Ekiti, na Nigéria."
            content3=""
          />
          <Team
            person="Chris Dawson"
            position="Designer gráfico"
            picture={Person10}
            content1="Chris dirige seu próprio estúdio de branding e web design no Reino Unido - Tallhat.com. Formado em design gráfico, trabalhou para uma grande agência digital em Londres como 'Head of Design', produzindo trabalhos criativos para marcas como Guinness, Peugeot e Microsoft. Ele é altamente visual e orientado por ideias, trabalhando com organizações para ajudar a comunicar suas ofertas aos clientes. Ele desenvolve marcas, materiais de marketing e projeta sites para uma ampla variedade de clientes."
            content2="Chris descobriu a Algorand no início de 2021 e tem explorado algumas ideias de projetos, lançando recentemente o SweeperKeeper - um projeto NFT não oficial da Copa do Mundo."
            content3=""
          />
          <Team
            person="Dennis Divine Abiola"
            position="Gerente da Comunidade"
            picture={Person12}
            content1="Atualmente, Dennis trabalha meio período como Community Manager na ALGOFAME, enquanto define e implementa campanhas de mídia social e comunicação para alinhar com as estratégias de marketing da ALGOFAME."
            content2="Dennis é um entusiasta de criptografia completo e adora tudo relacionado a blockchain/cripto. Dennis trabalhou com KAAFILA e Greenearth como (i) administrador líder da comunidade, (II) colaborador e gerente da comunidade, respectivamente."
            content3="Dennis visa envolver os membros da comunidade ALGOFAME com conversas tangíveis e levar o ALGOFAME ao próximo nível como um todo."
          />
          <Team
            person="Eyitayo Anjorin"
            position="Embaixador da Nigéria"
            picture={Person11}
            content1="Eyitayo Anjorin é um profissional experiente com experiência na indústria de blockchain, incluindo experiência como gerente de comunidade, engenheiro de blockchain e defensor de blockchain.
            Ele atua no setor desde 2016 e tem um forte conhecimento dos aspectos técnicos e comerciais da tecnologia blockchain."
            content2="Além de sua experiência em blockchain, Eyitayo é proficiente em escrita e codificação. Ele é um indivíduo completo e altamente qualificado, dedicado a impulsionar o sucesso na indústria de blockchain."
            content3=""
          />
        </div>
      </section>
      <section className=" bg-tertiary">
        <div
          className="p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <p className="text-heading text-white">
            os <span className="text-secondary">conselheiros</span> da fame
          </p>
          <div className="grid lg:grid-cols-3 pt-10 gap-10 text-grey">
          <Team
              person="Liran Peretz"
              position="Agritech expert"
              picture={Person15}
              content1="He is the co-founder and CEO of Alty, the all-in-one platform powering the world’s best crypto communities."
              content2="Before founding Alty, he worked in the digital finance & gaming sectors and oversaw the finance department at Playtech and managed the development of skilled teams in Antigua and the Philippines. He is an early crypto adopter, entering the space in 2011. Over the past 11 years, he has been actively engaged in the cryptocurrency community as a supporter and investor.
              Beyond his interests in crypto and finance, he has deep roots in commercial agriculture. Prior to his crypto and tech roles, he was a prominent figure in the regional agriculture segment, known for introducing Israeli drip and fertigation technology systems across the Caribbean region."
              content3="He is passionate about community development projects, the implementation of new technologies, and kitesurfing."
            />
            <Team
              person="Mokua (Erickson) Mogendi"
              position="Agritech expert"
              picture={Person16}
              content1="He believes technology can improve food security world wide, a generalist in Agricultural sector with all round skills in production in various enterprises within farming."
              content2="Livestock management,Crop management, Aquaculture management above all farm planning,budgeting and implementation.
              With a well resource mobilization and good structured agricultural policies, he believes we are able to feed ourselves without struggle."
              content3="Vertical farming, Aquaponics,Hydroponics,Greenhouse, among others can take us there."
            />
            <Team
              person="Andrea Frosinini"
              position="Supply Chain expert"
              picture={Person14}
              content1="I am a Senior Trade Finance professional, who mainly held this position in corporate  contexts, with a profound passion for innovation and digitisation. I recently had the opportunity to look at my job under a futuristic perspective since, in January 2020, I was asked to join Hyperledger community as Chairperson of Hyperledger Trade Finance SIG with the aim at offering a contribution to the industry modernization and development of new products exploiting on blockchain and DLTs, more in general. So far, I have managed to create a sound, cohesive network of professionals and companies operating in the field of enterprise blockchain solutions for Digital Trade & Supply Chain Finance, that is now giving the rise to some interesting projects and PoCs, built on different blockchain networks."
              content2="In late september 2021, I was hired by XDC Foundation as Business Development Manager. In this position, I managed to expand the community of developers and also gave rise to a series of project under the umbrella of several international institutions such as ITFA, with its, DNI-Digital Negotiable Initiative and TFDI  and TradeFinance Distribution Initiative,  ICC Singapore DSI-Digital Standards Initiative and BAFT in the US."
              content3="This has so far allowed me to further develop my experience of matters related not only to trade finance instruments and procedures, but, most importantly, on how these are being impacted by current business dynamics and digital transformation, with an eye always set on the market trends and developments at global scale."
            />
            <Team person="MG" position="Technology Advisor" picture={Person5} />
          </div>
        </div>
      </section>
      <section></section>
    </section>
  );
}
